import { useState, useEffect } from 'react';
import kentico from '../services/KenticoService.js';

import {connect} from 'react-redux';
import { getCulture } from '../store/culture/reducer';

import RRSSLink from './RRSSLink.js';

const Footer = (props) => {

  const [elements, setElements] = useState({});
  const [modular, setModular] = useState({});

  const getData = () => {

    let lang = `${props.culture.language}_${props.culture.country}`;

    return kentico.getItemData("footer", lang).then((response) => {
      setElements(response.data.item.elements);
      setModular(response.data.modular_content);
    })
    .catch((err) => {
      console.log(err);
    });

  }

  useEffect(getData, [props.culture]);

  return (
    <footer>
        <div id="info" className="container-fluid px-0 pb-3">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-0 mb-3 text-center payments-footer">
                      <div className="text-left">
                        <p className="mb-0">{ elements.payment_title ? elements.payment_title.value : '' }</p>

                        {
                          elements.payment_img &&
                          elements.payment_img.value &&
                          elements.payment_img.value.length > 0 ?
                            elements.payment_img.value.map((img, i) => (
                              <img key={i}
                                   src={img.url}
                                   alt={img.name}
                                   className="img-fluid webpay-redcompra d-inline-block p-2"/>
                            ))
                          :
                            null
                        }
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-8 offset-xl-0 offset-lg-0 offset-md-0 offset-sm-0 offset-2 rss-footer text-right">
                      <div className="text-center d-inline-block">
                        <p className="mb-0">{ elements.rrss_title ? elements.rrss_title.value : '' }</p>
                        <div className="d-block">
                          {
                            elements.rrss__links &&
                            elements.rrss__links.value &&
                            elements.rrss__links.value.length > 0 ?

                              elements.rrss__links.value.map((rs, i) => (
                                typeof modular[rs] !== "undefined" &&
                                typeof modular[rs].elements !== "undefined" ?
                                  <RRSSLink
                                    key={i}
                                    href={modular[rs].elements.url.value}
                                    content={modular[rs].elements.value.value}
                                    />
                                :
                                  null
                              ))
                            :
                              null
                          }
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container mt-4">
            <div className="row accordion" id="footer_cols">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <h5 className="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                        data-target="#footer_col_1" aria-controls="footer_col_1">
                        { elements.col_title_1 ? elements.col_title_1.value : '' } <i className="fas fa-plus-square float-right"></i>
                    </h5>
                    <h5 className="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                        { elements.col_title_1 ? elements.col_title_1.value : '' }
                    </h5>

                    <div id="footer_col_1" className="collapse d-xl-block d-lg-block d-md-block d-sm-block"
                         data-parent="#footer_cols">
                        <ul className="list-unstyled text-small">
                          {
                            elements.col_links_1 &&
                            elements.col_links_1.value &&
                            elements.col_links_1.value.length > 0 ?

                              elements.col_links_1.value.map((cl1, i) => (
                                typeof modular[cl1] !== "undefined" &&
                                typeof modular[cl1].elements !== "undefined" ?
                                  <li key={i}><a href={modular[cl1].elements.url.value}>
                                    <span className="hover-border-bottom-left">{modular[cl1].elements.value.value}</span></a>
                                  </li>

                                :
                                  null
                              ))
                            :
                              null
                          }
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <h5 className="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                        data-target="#footer_col_3" aria-controls="footer_col_3">
                        { elements.col_title_2 ? elements.col_title_2.value : '' } <i className="fas fa-plus-square float-right"></i>
                    </h5>
                    <h5 className="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                        { elements.col_title_2 ? elements.col_title_2.value : '' }
                    </h5>

                    <div id="footer_col_3" className="collapse d-xl-block d-lg-block d-md-block d-sm-block"
                         data-parent="#footer_cols">
                        <ul className="list-unstyled text-small">
                          {
                            elements.col_links_2 &&
                            elements.col_links_2.value &&
                            elements.col_links_2.value.length > 0 ?

                              elements.col_links_2.value.map((cl2, i) => (
                                typeof modular[cl2] !== "undefined" &&
                                typeof modular[cl2].elements !== "undefined" ?
                                  <li key={i}><a href={modular[cl2].elements.url.value}>
                                    <span className="hover-border-bottom-left">{modular[cl2].elements.value.value}</span></a>
                                  </li>

                                :
                                  null
                              ))
                            :
                              null
                          }
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <h5 className="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                        data-target="#footer_col_4" aria-controls="footer_col_4">
                        { elements.col_title_3 ? elements.col_title_3.value : '' } <i className="fas fa-plus-square float-right"></i>
                    </h5>
                    <h5 className="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                        { elements.col_title_3 ? elements.col_title_3.value : '' }
                    </h5>

                    <div id="footer_col_4" className="collapse d-xl-block d-lg-block d-md-block d-sm-block"
                         data-parent="#footer_cols">
                        <ul className="list-unstyled text-small">
                          {
                            elements.col_links_3 &&
                            elements.col_links_3.value &&
                            elements.col_links_3.value.length > 0 ?

                              elements.col_links_3.value.map((cl3, i) => (
                                typeof modular[cl3] !== "undefined" &&
                                typeof modular[cl3].elements !== "undefined" ?
                                  <li key={i}><a href={modular[cl3].elements.url.value}>
                                    <span className="hover-border-bottom-left">{modular[cl3].elements.value.value}</span></a>
                                  </li>

                                :
                                  null
                              ))
                            :
                              null
                          }
                        </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <h5 className="d-xl-none d-lg-none d-md-none d-sm-none cursor-pointer collapsed" data-toggle="collapse"
                        data-target="#footer_col_2" aria-controls="footer_col_2">
                        { elements.col_title_4 ? elements.col_title_4.value : '' } <i className="fas fa-plus-square float-right"></i>
                    </h5>
                    <h5 className="d-xl-block d-lg-block d-md-block d-sm-block d-none">
                        { elements.col_title_4 ? elements.col_title_4.value : '' }
                    </h5>

                    <div id="footer_col_2" className="collapse d-xl-block d-lg-block d-md-block d-sm-block"
                         data-parent="#footer_cols">
                        <ul className="list-unstyled text-small">
                          {
                            elements.col_links_4 &&
                            elements.col_links_4.value &&
                            elements.col_links_4.value.length > 0 ?

                              elements.col_links_4.value.map((cl4, i) => (
                                typeof modular[cl4] !== "undefined" &&
                                typeof modular[cl4].elements !== "undefined" ?
                                  <li key={i}><a href={modular[cl4].elements.url.value}>
                                    <span className="hover-border-bottom-left">{modular[cl4].elements.value.value}</span></a>
                                  </li>
                                :
                                  null
                              ))
                            :
                              null
                          }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="copyright" className="container-fluid px-0">
            <div className="separator-container py-3">
                <hr className="red border-top-8px m-0"/>
            </div>
            <div className="container pb-3">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <p className="mb-0 text-left">{ elements.copyright_text ? elements.copyright_text.value : '' }</p>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 offset-xl-4 offset-lg-4 offset-md-3 offset-sm-3 offset-3 text-right">
                        <img src={elements.logo_img ? elements.logo_img.value[0].url : ''} alt={elements.logo_img ? elements.logo_img.value[0].name : ''}
                             title={elements.logo_img ? elements.logo_img.value[0].name : ''} className="logo-jetsmart-footer img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(Footer);
