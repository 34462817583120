import { Field, Formik } from "formik";
import parse from "html-react-parser";
import $ from "jquery";
import { Fragment, useEffect, useRef, useState } from "react";

//Components
import CaseStatusBanner from "./CaseStatusBanner";

//Services
import claim from "../services/ClaimService.js";
import CurrencyInput from "./CurrencyInput";

//Libraries
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";

//Redux
import { connect } from "react-redux";
import { getCulture } from "../store/culture/reducer";

const Form = (props) => {
  const { elements, modular } = props;
  const defaultChannel = process.env.REACT_APP_DEFAULT_CHANNEL;
  const defaultType = process.env.REACT_APP_DEFAULT_TYPE;
  const defaultArea = process.env.REACT_APP_DEFAULT_AREA;
  const defaultConsultType = process.env.REACT_APP_DEFALT_CONSULT_TYPE;
  const defaultNotification = process.env.REACT_APP_DEFALT_CLIENT_NOTIFICATION;
  const defaultPanel = process.env.REACT_APP_DEFAULT_PNR;
  const defaultProductOtro = process.env.REACT_APP_DEFAULT_PRODUCT_OTRO;
  const submotiveData = process.env.REACT_APP_SUBMOTIVE;
  const defaultProduct = process.env.REACT_APP_PRODUCT;
  const ambiente = process.env.REACT_APP_ENV;
  const recaptchaRef = useRef();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [data, setData] = useState({});
  const [auxiliarData, setAuxiliarData] = useState({});
  const [fileGenerated, setFileGenerated] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSignedUrl, setFileSignedUrl] = useState("");
  const [activeStepOne, setActiveStepOne] = useState(true);
  const [activeStepTwo, setActiveStepTwo] = useState(false);
  const [activeStepThree, setActiveStepThree] = useState(false);
  const [numberCase, setNumberCase] = useState("");
  const [idInterno, setIdInterno] = useState("");
  const [messageModal, setMessageModal] = useState("");
  const [documentation, setDocumentation] = useState([]);
  const [attachFile, setAttachFile] = useState([]);
  const [errorCaptcha, setErrorCaptcha] = useState(true);
  const [pnrInvalid, setPnrInvalid] = useState(false);
  const [isGdsPnr, setIsGdsPnr] = useState(false);
  const [cultura, setCultura] = useState(props.culture.country);
  const [culturaLanguaje, setCulturaLanguaje] = useState(
    props.culture.language
  );
  const [defaultPrefix, setDefaultPrefix] = useState(null);
  const [defaultDocType, setDefaultDocType] = useState(null);
  const [defaultDocTypeLabel, setDefaultDocTypeLabel] = useState(null);
  const [tipoSolicitud, setTipoSolicitud] = useState(null);
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [ruc, setRuc] = useState("");
  const [claimTitle, setClaimTitle] = useState("");
  const [tipoProducto, setTipoProducto] = useState("");
  const maxDescriptionLength = cultura === "pe" ? 450 : 500;
  const maxClaimTitleLength = 50;
  const [jatCommercialAddress, setJatCommercialAddress] = useState("");
  const [vueloConConexion, setVueloConConexion] = useState("");
  const [esVueloAA, setESVueloAA] = useState(false);
  const onlyLettersAndNumbersDireccionRegExp = /^[a-zA-Z0-9 ' ' # -]*$/;
  const onlyLettersAndNumbersRegExp = /^[a-zA-Z0-9]*$/;
  const onlyLettersAndNumbersRegExpGuion = /^[a-zA-Z0-9 -]*$/;
  const onlyMailRegExp =
    /^[a-zA-Z0-9+@+.+ñ+Ñ+¸+¨+´+zäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ.+ +-_]*$/;
  const onlyLettersRegExp = /^[a-zA-ZáÁéÉíÍóÓúÚ ñÑÀ-ÿṽṼ]*$/;
  const onlyNumbersRegExp = /^[0-9]*$/;
  const onlyNumbersCurrencyRegExp = /^[0-9]+([.][0-9]{2})?$/;
  const [descriptionExceeded, setDescriptionExceeded] = useState(false);
  const [errorDescriptionExceeded, setErrorDescriptionExceeded] = useState("");
  const [showRuc, setShowRuc] = useState(false);
  const [showAmount, setShowAmount] = useState(false);
  const [countDescription, setCountDescription] = useState(0);
  const [checkAge, setCheckAge] = useState(false);
  const initialValues = {
    nombrerppe: "",
    domiciliorppe: "",
    telefonorppe: "",
    correorppe: "",
  };
  const [pnrGdsDisabled, setPnrGdsDisabled] = useState(true);
  const mainRef = useRef(null);
  const groupCriterioRef = useRef();
  const [errorEmail, setErrorEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState("pnr_js");

  // Function to handle the change on reCAPTCHA
  // const handleCaptchaChange = (value) => {
  //   console.log("Captcha value:", value);
  //   setCaptchaValue(value);
  // };

  //metodo para derivar reservas de AA
  const handleOptionChangeAA = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "pnr_aa") {
      //mensaje que deriva a site de American Airline
      setMessageModal(
        parse(
          elements.aa_message_page_1 ? elements.aa_message_page_1.value : "",
          {
            trim: true,
          }
        )
      );
      /* Activa Modal */
      window.$("#dev_modal").modal("show");
    }
  };

  // Get data PNR
  const getDataPnr = (pnr, criteriaGsd) => {
    //setShowModal(false);
    /* Esconde Modal */
    window.$("#dev_modal").modal("hide");

    setPnrInvalid(false);

    let loading = window.newRtLoader(".loading", {
      logo_color: "white", // white or colored
      background_color: "rgba(255,255,255,.97)", // any color in hex or rgb/rgba
      airplane_color: "#163a70", // any color in hex
      dots_color: "#163a70", // any color in hex
    });

    // window.$('html, body').animate({scrollTop:$('#groups-form').position().top}, 'slow');
    window
      .$("html, body")
      .animate({ scrollTop: $("#divcenter").offset().top }, "slow");
    loading.startLoader();

    let lastname = "";
    let email = "";

    //validacion es correo o apellido
    if (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        criteriaGsd
      )
    ) {
      email = criteriaGsd;
    } else {
      lastname = criteriaGsd;
    }

    return claim
      .getPNR(pnr, email, lastname)
      .then((response) => {
        // //REQ-1791 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// ZZZ
        // console.log('response del retrive Locator PNR : ',response.data.data.journeys)
        // console.log('journeys[0].stops : ',response.data.data.journeys[0].stops)
        // console.log('response.data.data.journeys[0].flightType PNR : ',response.data.data.journeys[0].flightType)

        if (
          response.data.data.journeys[0].stops > 0 &&
          response.data.data.journeys[0].flightType === 4
        ) {
          setVueloConConexion("Tipo de vuelo: Connect");
        } else {
          setVueloConConexion("");
        }

        ////////////////////////////////////////////////////////////////PMR-1706 SFD 2327 AA - Restricción acceso a Reclamos: Hacer validación en el front segun el PNR ingresado
        let arrayJourneys = response.data.data.journeys;
        let flag = false;
        let cantidadAA = 0;
        let cantJourneys = 0;
        arrayJourneys.forEach((element) => {
          let arraySegment = element.segments;
          cantJourneys++;
          arraySegment.forEach((elementSegment) => {
            if (elementSegment.identifier.carrierCode === "AA") {
              cantidadAA++;
              flag = true;
            }
          });
        });

        //si el pnr es mixto
        if (flag && cantidadAA !== cantJourneys) {
          setESVueloAA(true);
          setSelectedOption("pnr_js");
        }

        //si el pnr es de american
        if (flag && cantidadAA === cantJourneys) {
          setESVueloAA(false);
          setSelectedOption("pnr_aa");
        }

        //si el pnr es jetsmart
        if (!flag) {
          setESVueloAA(false);
          setSelectedOption("pnr_js");
        }

        ////////////////////////////////////////////////////////////////////////
        if (response.data.data.systemCode == "1A") {
          setIsGdsPnr(true);
        } else {
          setIsGdsPnr(false);
        }
        setPnrInvalid(false);
        loading.stopLoader();

        mainRef?.current?.scrollIntoView({ behavior: "smooth" });
      })
      .catch((err) => {
        setPnrInvalid(true);
        loading.stopLoader();
        setMessageModal(
          elements.error_message_general
            ? elements.error_message_general.value
            : ""
        );
        /* Activa Modal */

        window.$("#dev_modal").modal("show");
        mainRef?.current?.scrollIntoView({ behavior: "smooth" });
      });
  };

  //Change data PNR and find it
  const handlePnrFieldChange = (e, values, handleChangeP, setFieldValue) => {
    let pnr = e.target.value;

    if (pnr !== "" && pnr.length === 6) {
      if (values.criteriaGds !== "") {
        getDataPnr(pnr.toUpperCase(), values.criteriaGds);
      }
    } else {
      setPnrInvalid(true);
    }

    handleChangeP(e);
    //console.log(pnrData);
  };

  //esta es una validacion con el email o el apellido
  const handlePnrFieldCriterio = (e, setFieldValue, values) => {
    let criterio = e.target.value;
    let pnrValida = values.pnr;
    const isValid = new RegExp("^[a-zA-Z ]+$").test(criterio);

    if (criterio !== "") {
      if (/\S+@/.test(criterio)) {
        //es correo

        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(criterio)) {
          //si pnr != '' entonces validar
          if (values.pnr !== "") {
            getDataPnr(pnrValida.toUpperCase(), values.criteriaGds);
          } else {
            setPnrGdsDisabled(false);
          }
          // setPnrGdsDisabled(false);
        } else {
          setMessageModal(
            elements.form_user_input_email_error_invalid
              ? elements.form_user_input_email_error_invalid.value
              : ""
          );
          // setMessageModal('Debe ingresar un email valido');
          setFieldValue("pnr", "");
          setPnrGdsDisabled(true);
          window.$("#dev_modal").modal("show");
        }
      } else {
        //es apellido
        if (values.pnr !== "") {
          getDataPnr(pnrValida.toUpperCase(), values.criteriaGds);
        } else {
          setPnrGdsDisabled(false);
        }
        // setPnrGdsDisabled(false);
      }
    } else {
      setFieldValue("pnr", "");
      setPnrGdsDisabled(true);
    }
  };

  const handlePnrFieldBlur = (e, values, handleBlurP) => {
    e.target.value = values.pnr.toUpperCase();
    handleBlurP(e);
  };

  const validaCity = (values, setFieldValue, setFieldTouched) => {
    if (!onlyLettersRegExp.test(values.city)) {
      setFieldValue("city", "");
      setFieldTouched("city", true, true);
    }
  };

  const validaFillUp = (e, values, cultura, setFieldValue) => {
    if (!onlyLettersRegExp.test(values.city)) {
      setFieldValue("city", "");
    }

    //se valida si la reserva ingresada es de AA , si es asi se deriva a la web de AA
    if (selectedOption === "pnr_aa") {
      setMessageModal(
        parse(
          elements.aa_message_page_1 ? elements.aa_message_page_1.value : "",
          {
            trim: true,
          }
        )
      );
      window.$("#dev_modal").modal("show");
      e.preventDefault();
      return;
    }

    if (cultura === "pe" && values.requestType === "R_") {
      if (
        (values.requestType === "" ||
          values.description === "" ||
          values.claimTitle === "" ||
          values.ruc === "") &&
        (values.criteriaGds !== "" || values.pnr !== "")
      ) {
        window
          .$("html, body")
          .animate({ scrollTop: $("#groups-form").position().top }, "slow");
      }
      if (
        (values.requestType === "" ||
          values.description === "" ||
          values.claimTitle === "" ||
          values.ruc === "") &&
        (values.criteriaGds === "" || values.pnr === "")
      ) {
        window
          .$("html, body")
          .animate({ scrollTop: $("#groups-form").position().top }, "slow");
      }
      if (
        values.requestType !== "" &&
        values.description !== "" &&
        values.claimTitle !== "" &&
        values.ruc !== "" &&
        (values.criteriaGds === "" || values.pnr === "")
      ) {
        window
          .$("html, body")
          .animate({ scrollTop: $("#div1").offset().top }, "slow");
      }
    } else {
      if (cultura === "pe" && values.requestType !== "R_") {
        if (
          (values.requestType === "" ||
            values.description === "" ||
            values.claimTitle === "") &&
          (values.criteriaGds === "" || values.pnr === "")
        ) {
          window
            .$("html, body")
            .animate({ scrollTop: $("#groups-form").position().top }, "slow");
        }

        if (
          (values.requestType === "" ||
            values.description === "" ||
            values.claimTitle === "") &&
          (values.criteriaGds !== "" || values.pnr !== "")
        ) {
          window
            .$("html, body")
            .animate({ scrollTop: $("#groups-form").position().top }, "slow");
        }
      } else {
        if (values.requestType === "R_") {
          if (
            (values.product === "" ||
              values.requestType === "" ||
              values.description === "" ||
              values.amount === "") &&
            (values.criteriaGds !== "" || values.pnr !== "")
          ) {
            window
              .$("html, body")
              .animate({ scrollTop: $("#groups-form").position().top }, "slow");
          }
          if (
            (values.product === "" ||
              values.requestType === "" ||
              values.description === "" ||
              values.amount === "") &&
            (values.criteriaGds === "" || values.pnr === "")
          ) {
            window
              .$("html, body")
              .animate({ scrollTop: $("#groups-form").position().top }, "slow");
          }
          if (
            values.product !== "" &&
            values.requestType !== "" &&
            values.description !== "" &&
            values.amount !== "" &&
            (values.criteriaGds === "" || values.pnr === "")
          ) {
            window
              .$("html, body")
              .animate({ scrollTop: $("#div1").offset().top }, "slow");
          }
        } else {
          if (
            (values.product === "" ||
              values.requestType === "" ||
              values.description === "") &&
            (values.criteriaGds !== "" || values.pnr !== "")
          ) {
            window
              .$("html, body")
              .animate({ scrollTop: $("#groups-form").position().top }, "slow");
          }
          if (
            (values.product === "" ||
              values.requestType === "" ||
              values.description === "") &&
            (values.criteriaGds === "" || values.pnr === "")
          ) {
            window
              .$("html, body")
              .animate({ scrollTop: $("#groups-form").position().top }, "slow");
          }
          if (
            values.product !== "" &&
            values.requestType !== "" &&
            values.description !== "" &&
            (values.criteriaGds === "" || values.pnr === "")
          ) {
            window
              .$("html, body")
              .animate({ scrollTop: $("#div1").offset().top }, "slow");
          }
        }
      }
    }
  };

  //Back step 1
  const backAction = (e) => {
    setActiveStepOne(true);
    setActiveStepTwo(false);
    window
      .$("html, body")
      .animate({ scrollTop: $("#groups-form").position().top }, "slow");
  };

  //Back to home
  const backHomeBtn = (e) => {
    let rutaback = `/${cultura}/${culturaLanguaje}/${process.env.REACT_APP_ROUTE_NAME}`;
    window.location.replace(rutaback);
    // window.location.replace("/");
  };

  const validatePnr = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!onlyLettersAndNumbersRegExp.test(keyValue)) {
      event.preventDefault();
      return;
    }
  };

  const validatePnrPaste = (e, handleBlurP) => {
    let pnrPaste = e.clipboardData.getData("Text").trim();
    if (!onlyLettersAndNumbersRegExp.test(pnrPaste)) {
      setMessageModal(
        elements.form_user_input_document_type_error
          ? elements.form_user_input_document_type_error.value
          : ""
      );
      window.$("#dev_modal").modal("show");
      e.preventDefault();
      return;
    }
  };

  const validateCriterioPaste = (e, handleBlurP) => {
    let criterioPaste = e.clipboardData.getData("Text").trim();
    if (!onlyMailRegExp.test(criterioPaste)) {
      setMessageModal(
        elements.form_user_input_document_type_error
          ? elements.form_user_input_document_type_error.value
          : ""
      );
      window.$("#dev_modal").modal("show");
      e.preventDefault();
      return;
    }
  };

  const validatePasteEmail = (e) => {
    let criterioPaste = e.clipboardData.getData("Text").trim();
    setErrorEmail("");
    if (!onlyMailRegExp.test(criterioPaste)) {
      setMessageModal(
        elements.form_user_input_document_type_error
          ? elements.form_user_input_document_type_error.value
          : ""
      );
      window.$("#dev_modal").modal("show");
      e.preventDefault();
      return;
    }
  };

  const validateEmailEnvio = (value) => {
    let error;
    if (!value) {
      return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return false;
    } else {
      return true;
    }
  };

  //valida campos menor de edad
  const validatePasteLetraNumero = (e, handleBlurP) => {
    let campoPaste = e.clipboardData.getData("Text");
    if (!onlyLettersRegExp.test(campoPaste)) {
      setMessageModal(
        elements.only_letters_numbers_error
          ? elements.only_letters_numbers_error.value
          : ""
      );
      window.$("#dev_modal").modal("show");
      e.preventDefault();
      return;
    }
  };

  //valida campos menor de edad
  const limpiarError = (e) => {
    if (e.target.value === "") {
      setErrorEmail("");
    }
  };

  //valida
  const limpiarErrorMas = (e) => {
    setErrorEmail("");
  };

  const enviarMail = (e) => {
    e.preventDefault();
    let flag = validateEmailEnvio(e.target.email.value);

    let loading = window.newRtLoader(".loading", {
      logo_color: "white", // white or colored
      background_color: "rgba(255,255,255,.97)", // any color in hex or rgb/rgba
      airplane_color: "#163a70", // any color in hex
      dots_color: "#163a70", // any color in hex
    });

    if (e.target.email.value != "") {
      if (flag) {
        setErrorEmail("");

        try {
          loading.startLoader();

          if (fileGenerated) {
            let payload = {
              fileName,
              email: e.target.email.value,
              numberCase: numberCase,
            };
            claim.sendMail(payload).then(() => {
              loading.stopLoader();
              setMessageModal(
                elements.bajada.value
                  ? parse(elements.bajada.value, { trim: true })
                  : ""
              );
              window.$("#dev_modal").modal("show");
            });
          } else {
            handleGeneratePdf(false).then((auxFileName) => {
              let payload = {
                fileName: auxFileName,
                email: e.target.email.value,
                numberCase: numberCase,
              };
              claim.sendMail(payload).then(() => {
                loading.stopLoader();
                setMessageModal(
                  elements.bajada.value
                    ? parse(elements.bajada.value, { trim: true })
                    : ""
                );
                window.$("#dev_modal").modal("show");
              });
            });
          }
        } catch (error) {
          loading.stopLoader();
          setMessageModal(
            elements.bajada_2.value
              ? parse(elements.bajada_2.value, { trim: true })
              : ""
          );
          window.$("#dev_modal").modal("show");
        }
      } else {
        loading.stopLoader();
        setMessageModal(
          elements.form_user_input_email_error_invalid.value
            ? parse(elements.form_user_input_email_error_invalid.value, {
                trim: true,
              })
            : ""
        );
        window.$("#dev_modal").modal("show");
      }
    } else {
      setErrorEmail("Campo requerido.");
    }
  };

  //Validate pnr
  const validateCriterio = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!onlyMailRegExp.test(keyValue)) {
      event.preventDefault();
      return;
    }
  };

  const validateCampoLetras = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!onlyLettersRegExp.test(keyValue)) {
      event.preventDefault();
      return;
    }
  };

  const validateCampoLetrasNumeros = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!onlyLettersAndNumbersDireccionRegExp.test(keyValue)) {
      event.preventDefault();
      return;
    }
  };

  //Validate firstname
  const validateFirstname = (value) => {
    let error;
    if (!value) {
      error = elements.form_user_input_name_error
        ? elements.form_user_input_name_error.value
        : "";
    }
    if (!onlyLettersRegExp.test(value)) {
      error = elements.only_letters_error
        ? elements.only_letters_error.value
        : "";
    }
    return error;
  };

  //Validate address
  const validateAddress = (value) => {
    let error;
    if (!value && cultura === "pe") {
      error = elements.form_user_input_address_error.value || "";
    }

    return error;
  };

  //Validate city
  const validateCity = (value) => {
    let error;
    if (cultura === "pe") {
      if (value && !onlyLettersRegExp.test(value)) {
        error = elements.only_letters_error
          ? elements.only_letters_error.value
          : "";
      }
    } else {
      if (!value) {
        error = elements.form_user_input_name_error
          ? elements.form_user_input_name_error.value
          : "";
      }
      if (!onlyLettersRegExp.test(value)) {
        error = elements.only_letters_error
          ? elements.only_letters_error.value
          : "";
      }
    }
    return error;
  };

  //Validate titulo reclamo
  const validateClaimTitle = (value) => {
    let error;

    if (!value) {
      error = elements.form_service_input_case_title_error.value || "";
    }
    if (!onlyLettersRegExp.test(value)) {
      error = elements.only_letters_error
        ? elements.only_letters_error.value
        : "";
    }
    setClaimTitle(value);
    return error;
  };

  //Validate lastname
  const validateLastname = (value) => {
    let error;
    if (!value) {
      error = elements.form_user_input_lastname_error.value;
    }
    if (!onlyLettersRegExp.test(value)) {
      error = elements.only_letters_error
        ? elements.only_letters_error.value
        : "";
    }
    return error;
  };

  //Validate email
  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = elements.form_user_input_email_error_empty.value;
      return error;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = elements.form_user_input_email_error_invalid.value;
      return error;
    }
  };

  //Validate document number
  const validateIDNumber = (value) => {
    let error;
    if (!value) {
      error = elements.form_user_input_document_number_error.value;
    }
    if (!onlyLettersAndNumbersRegExpGuion.test(value)) {
      error = elements.only_letters_numbers_error
        ? elements.only_letters_numbers_error.value
        : "";
    }
    return error;
  };

  //Validate phone
  const validatePhone = (value) => {
    let error;
    if (cultura === "pe") {
      if (value && !onlyNumbersRegExp.test(value)) {
        error = elements.only_numbers_error
          ? elements.only_numbers_error.value
          : "";
      }
    } else {
      if (!value) {
        error = elements.form_user_input_phone_number_error.value;
      }
      if (!onlyNumbersRegExp.test(value)) {
        error = elements.only_numbers_error
          ? elements.only_numbers_error.value
          : "";
      }
    }
    return error;
  };

  //Validate phone
  const validatePhonePe = (event) => {
    const keyCode = Number(event.target.value);
    const keyValue = String.fromCharCode(keyCode);
    if (
      event.target.value.length > 12 ||
      (event.which != 8 &&
        event.which != 0 &&
        (event.which < 48 || event.which > 57))
    ) {
      event.preventDefault();
      return false;
    }
  };

  //numdoc
  const validateNumDocAlfaNum = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!onlyLettersAndNumbersRegExpGuion.test(keyValue)) {
      event.preventDefault();
      return;
    }
  };

  //Validate terms and conditions
  const validateTermsAndConditions = (e, values) => {
    let error;
    if (cultura === "pe") {
      // En reclamos cultura PE, terminos y condiciones es opcional.
      if (!e && values.requestType !== "R_") {
        error = elements.form_service_input_terms_and_conditions_error.value;
      }
    } else {
      // Para el resto de culturas se mantiene obligatorio
      if (!e) {
        error = elements.form_service_input_terms_and_conditions_error.value;
      }
    }

    return error;
  };

  //Validate terms and conditions Menor de Edad
  const validateTermsAndConditionsAge = (value) => {
    if (value) {
      setCheckAge(true);
    } else {
      setCheckAge(false);
    }
  };

  //Validate amount
  const validateAmount = (value) => {
    let error;
    if (cultura === "pe") {
      if (value && !onlyNumbersCurrencyRegExp.test(value)) {
        error = elements.only_positive_numbers_error
          ? elements.only_positive_numbers_error.value
          : "";
      }
    } else {
      if (!value) {
        error = elements.form_service_input_amount_error.value;
      } else if (!onlyNumbersCurrencyRegExp.test(value)) {
        error = elements.only_positive_numbers_error
          ? elements.only_positive_numbers_error.value
          : "";
      }
    }
    return error;
  };

  //Validate description
  const validateDescription = (value) => {
    let error;
    let resp = false;

    if (value.length >= maxDescriptionLength) {
      resp = true;
    }
    if (!value) {
      error = elements.form_service_input_case_description_error.value;
    }

    setCountDescription(value.length);
    setDescriptionExceeded(resp);

    return error;
  };

  //Convert file to base64
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        let separarData = baseURL.split(",");
        resolve([separarData[1], file.name]);
      };
    });
  };

  const handleFileFieldChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const maxFiles = 3;
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    const isWithinFileLimit =
      newFiles.length + documentation.length <= maxFiles;
    const totalSize =
      newFiles.reduce((acc, file) => acc + file.size, 0) +
      documentation.reduce((acc, doc) => acc + doc.fileSize, 0);

    if (!isWithinFileLimit) {
      showErrorMessage(
        elements.form_service_section_documentation_count_error.value
      );
      return;
    }

    if (totalSize > maxSize) {
      showErrorMessage(
        elements.form_service_section_documentation_count_error.value
      );
      return;
    }

    const validFileTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/jpg",
    ];

    const validExtensions = ["jpg", "jpeg", "png", "pdf"];
    const allFilesValid = newFiles.every(
      (file) =>
        validFileTypes.includes(file.type) &&
        validExtensions.includes(file.name.split(".").pop().toLowerCase())
    );

    if (!allFilesValid) {
      showErrorMessage(
        elements.form_service_section_documentation_count_error.value
      );
      return;
    }

    const processedFiles = newFiles.map((file) => ({
      filename: file.name,
      fileB64: "", // Assuming getBase64 is an async function that sets this value
      fileSize: file.size,
    }));

    Promise.all(newFiles.map((file) => getBase64(file))).then((base64Files) => {
      const updatedFiles = processedFiles.map((file, index) => {
        return {
          ...file,
          fileB64: base64Files[index][0],
        };
      });

      setDocumentation([...documentation, ...updatedFiles]);
      setAttachFile([
        ...attachFile,
        ...updatedFiles.map((file) => file.filename),
      ]);
    });
  };
  const showErrorMessage = (message) => {
    setMessageModal(message);
    window.$("#dev_modal").modal("show");
  };

  const removeElementFromAttachments = (e, index) => {
    e.preventDefault();

    let doc1 = documentation.filter((doc, i) => {
      return i !== index;
    });

    let attach1 = attachFile.filter((attach, i1) => {
      return i1 !== index;
    });

    setDocumentation(doc1);
    setAttachFile(attach1);
  };

  //ReCAPTCHA event
  const noErrorCaptchaDetected = () => {
    setErrorCaptcha(false);
  };

  //ReCAPTCHA event
  const errorCaptchaDetected = () => {
    setErrorCaptcha(true);
  };

  //Change product
  const validateOnProductChange = (e, values, handleChangeF) => {
    let index = e.nativeEvent.target.selectedIndex;
    let text = e.nativeEvent.target[index].text;
    setTipoProducto(text);
    handleChangeF(e);
  };

  //Change requestType
  const validateOnChangeEvent = (e, values, handleChangeF) => {
    //console.log(e.target.value);
    values.product = "";
    values.pnr = "";
    values.amount = "";
    setPnrInvalid(false);
    validateRucDisplaying(e.target.value);

    let index = e.nativeEvent.target.selectedIndex;
    let text = e.nativeEvent.target[index].text;
    setTipoSolicitud(text);
    handleChangeF(e);
  };
  // //Change TypeDoc
  const validateOnChangeEventTypeDoc = (e, values, handleChangeF) => {
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    const value = e.nativeEvent.target[index].value;

    // setTipoDocumento(text);
    setDefaultDocTypeLabel(text);
    setDefaultDocType(value);
    handleChangeF(e);
  };

  const validateRucField = (value) => {
    let error;
    if (!value) {
      error = elements.form_service_input_case_description_error.value;
    }

    return error;
  };

  const validateOnChangeRuc = (e, values, handleChangeF) => {
    let index = e.nativeEvent.target.selectedIndex;
    let text = e.nativeEvent.target[index].text;

    switch (index) {
      case 1:
        setJatCommercialAddress(elements.address_cl.value);
        break;
      case 2:
        setJatCommercialAddress(elements.address_ar.value);
        break;
      case 3:
        setJatCommercialAddress(elements.address_pe.value);
        break;
      case 4:
        setJatCommercialAddress(elements.address_co.value);
        break;
      case 5:
        setJatCommercialAddress(elements.address_br.value);
        break;
      case 6:
        setJatCommercialAddress(elements.address_uy.value);
        break;
      case 7:
        setJatCommercialAddress(elements.address_py.value);
        break;
      default:
        setJatCommercialAddress("");
    }

    setRuc(text);
    handleChangeF(e);
  };

  const validateRucDisplaying = (value) => {
    if (value === "R_") {
      setShowAmount(true);
    } else {
      setShowAmount(false);
    }

    if ((value === "R_" || value === "Q_") && props.culture.country === "pe") {
      setShowRuc(true);
    } else {
      setShowRuc(false);
    }
  };

  const formateaIdInterno = (id_interno) => {
    let id_internoFormateado = "";

    if (Number(id_interno) < 10) {
      id_internoFormateado = "0000" + id_interno;
    }
    if (Number(id_interno) >= 10 && Number(id_interno) < 100) {
      id_internoFormateado = "000" + id_interno;
    }
    if (Number(id_interno) >= 100 && Number(id_interno) < 1000) {
      id_internoFormateado = "00" + id_interno;
    }
    if (Number(id_interno) >= 1000 && Number(id_interno) < 10000) {
      id_internoFormateado = "0" + id_interno;
    }
    if (Number(id_interno) >= 10000) {
      id_internoFormateado = id_interno;
    }

    return id_internoFormateado;
  };



  useEffect(() => {
     const documentTypeName = {
    cl: "RUT",
    us: "RUT",
    ar: "DNI",
    pe: "DNI",
    ec: "DNI",
    co: "NIT",
    br: "CPF",
    uy: "CI",
    py: "CI",
  };
    if (!jatCommercialAddress) {
      switch (props.culture.country) {
        case "ec":
          setJatCommercialAddress(elements.address_ec.value);
          break;
        case "pe":
          setJatCommercialAddress(elements.address_pe.value);
          break;
        case "cl":
          setJatCommercialAddress(elements.address_cl.value);
          break;
        case "ar":
          setJatCommercialAddress(elements.address_ar.value);
          break;
        case "co":
          setJatCommercialAddress(elements.address_co.value);
          break;
        case "uy":
          setJatCommercialAddress(elements.address_uy.value);
          break;
        case "py":
          setJatCommercialAddress(elements.address_py.value);
          break;
        case "br":
          setJatCommercialAddress(elements.address_br.value);
          break;
        default:
          setJatCommercialAddress("");
          break;
      }
    }

    if (defaultDocTypeLabel === null) {
      const tipoDocSel = documentTypeName[cultura];

      setDefaultDocTypeLabel(tipoDocSel);
    }
  }, [
    cultura,
    elements,
    defaultDocTypeLabel,
    jatCommercialAddress,
    props.culture.country,
  ]);

  useEffect(() => {
    setDefaultPrefix(elements.form_user_input_prefix_default.value);
    setDefaultDocType(elements.form_user_input_document_type_default.value);
    setErrorDescriptionExceeded(elements.error_description_exceeded.value);
  }, [elements]);

  const DocTypes = {
    cl: 1,
    us: 1,
    ar: 2,
    pe: 2,
    ec: 2,
    co: 4,
    br: 5,
    uy: 6,
    py: 6,
    default: defaultDocType,
  };

  const DocTypesNumber = {
    ar: "+54",
    pe: "+51",
    co: "+57",
    br: "+55",
    ec: "+593",
    uy: "+598",
    py: "+595",
    defaultPrefix: defaultPrefix,
  };

  const CodeCountry = {
    cl: "2",
    ar: "1",
    pe: "5",
    co: "4",
    br: "3",
    ec: "10",
    uy: "7",
    py: "8",
    defaultCode: "6",
  };

  const getDate = () => {
    let date = moment(new Date()).utc().format("YYYY-MM-DD");
    return date;
  };

  const handleGeneratePdf = async (needDownload) => {
    let auxFileName = "";
    let loading = window.newRtLoader(".loading", {
      logo_color: "white", // white or colored
      background_color: "rgba(255,255,255,.97)", // any color in hex or rgb/rgba
      airplane_color: "#163a70", // any color in hex
      dots_color: "#163a70", // any color in hex
    });
    try {
      if (fileGenerated) {
        window.open(fileSignedUrl);
      } else {
        window
          .$("html, body")
          .animate({ scrollTop: $("#form-confirmation").offset().top }, "slow");
        loading.startLoader();
        await claim
          .createPdfFile({ ...auxiliarData, numberCase, idInterno, claimTitle })
          .then((response) => {
            if (needDownload) {
              window.open(response.data.data.pdfSignedUrl);
            }
            setFileGenerated(true);
            setFileSignedUrl(response.data.data.pdfSignedUrl);
            setFileName(response.data.data.pdfFilename);
            auxFileName = response.data.data.pdfFilename;
            loading.stopLoader();
          })
          .catch((e) => {
            setMessageModal(elements.title.value ? elements.title.value : "");
            window.$("#dev_modal").modal("show");
            loading.stopLoader();
          });
      }
    } catch (error) {
      loading.stopLoader();
    }

    return auxFileName;
  };

  const limpiarCamposMenorDeEdad = (id) => {
    if (checkAge) {
    }
  };

  return (
    <Fragment>
      {defaultPrefix !== null && defaultDocType !== null ? (
        <Fragment>
          <div className="rt-widget">
            <div className="loading">
              <div className="container" id="card-container">
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <h3 className="mb-0 font-weight-bold">
                      {" "}
                      {elements.title_part1
                        ? elements.title_part1.value
                        : ""}{" "}
                    </h3>
                    <div className="d-flex">
                      <hr className="title-hr mr-4" />
                      <h3 className="font-weight-bold text-uppercase mx-md-0 mb-sm-2 mb-0 mx-auto">
                        {elements.title_part2 ? elements.title_part2.value : ""}
                      </h3>
                      <hr className="title-hr ml-4" />
                    </div>
                    <div className="warning-text">
                      <img
                        src={
                          elements.reclamo_img
                            ? elements.reclamo_img.value[0].url
                            : ""
                        }
                        alt=""
                        className="mr-3"
                      />
                      <div>
                        <p className="mb-0">
                          <strong>
                            {elements.aa_message_page_2
                              ? parse(elements.aa_message_page_2.value, {
                                  trim: true,
                                })
                              : ""}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 justify-content-center">
                  <div className="col-12" id="groups-form">
                    <div className="group-card py-4">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-10 mt-4 mx-auto text-center">
                            <div className="text-center">
                              <ul
                                className="nav nav-pills mt-4 mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0 justify-content-center"
                                id="reclamos-steps"
                              >
                                <li className="nav-item" id="item-1">
                                  <hr />
                                  <div
                                    className={`banner ${
                                      activeStepOne
                                        ? "nav-link active"
                                        : "nav-link done"
                                    }`}
                                    id="questions-1-tab"
                                    aria-controls="questions-1"
                                    aria-selected="true"
                                    data-question="1"
                                  >
                                    <div className="p-2 step-icon">
                                      <div className="p2 step-icon-inner">
                                        <span className="question-step-icon img-fluid">
                                          {elements.reclamos_step_circle_one
                                            ? elements.reclamos_step_circle_one
                                                .value
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <p className="question-step-title mb-0">
                                      {elements.reclamos_step_title_one
                                        ? elements.reclamos_step_title_one.value
                                        : ""}
                                    </p>
                                  </div>
                                </li>
                                <li className="nav-item" id="item-2">
                                  <hr />
                                  <div
                                    className={`banner ${
                                      activeStepTwo
                                        ? "nav-link active"
                                        : activeStepThree
                                        ? "nav-link done"
                                        : "nav-link"
                                    }`}
                                    id="questions-2-tab"
                                    aria-controls="questions-2"
                                    aria-selected="true"
                                    data-question="2"
                                  >
                                    <div className="p-2 step-icon">
                                      <div className="p2 step-icon-inner">
                                        <span className="question-step-icon img-fluid">
                                          {elements.reclamos_step_circle_two
                                            ? elements.reclamos_step_circle_two
                                                .value
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <p className="question-step-title mb-0">
                                      {elements.reclamos_step_title_two
                                        ? elements.reclamos_step_title_two.value
                                        : ""}
                                    </p>
                                  </div>
                                </li>
                                <li className="nav-item" id="item-3">
                                  <hr />
                                  <div
                                    className={`banner ${
                                      activeStepThree
                                        ? "nav-link active"
                                        : "nav-link"
                                    }`}
                                    id="questions-3-tab"
                                    aria-controls="questions-3"
                                    aria-selected="true"
                                    data-question="3"
                                  >
                                    <div className="p-2 step-icon">
                                      <div className="p2 step-icon-inner">
                                        <span className="question-step-icon img-fluid">
                                          {elements.reclamos_step_circle_three
                                            ? elements
                                                .reclamos_step_circle_three
                                                .value
                                            : ""}
                                        </span>
                                      </div>
                                    </div>
                                    <p className="question-step-title mb-0">
                                      {elements.reclamos_step_title_three
                                        ? elements.reclamos_step_title_three
                                            .value
                                        : ""}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-content" id="questions-stepsContent">
                        <div
                          className={`banner ${
                            activeStepOne
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }`}
                          id="questions-1"
                          role="tabpanel"
                          aria-labelledby="questions-1-tab"
                        >
                          <Formik
                            initialValues={{
                              firstname: "",
                              lastname: "",
                              email: "",
                              idType: DocTypes[cultura] || DocTypes.default,
                              idNum: "",
                              prefix:
                                DocTypesNumber[cultura] ||
                                DocTypesNumber.defaultPrefix,
                              phoneNumber: "",
                              address: "",
                            }}
                            validate={(values) => {
                              const errors = {};

                              if (!values.idType) {
                                errors.idType =
                                  elements.form_user_input_document_type_error.value;
                                return errors;
                              }

                              if (!values.idNum) {
                                errors.idNum =
                                  elements.form_user_input_document_type_error.value;
                                return errors;
                              }

                              if (!values.prefix) {
                                errors.prefix =
                                  elements.form_user_input_prefix_error.value;
                                return errors;
                              }

                              if (!values.address && cultura === "pe") {
                                errors.address =
                                  elements.form_user_input_address_error.value;
                                return errors;
                              }
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                              setData(
                                JSON.parse(JSON.stringify(values, null, 2))
                              );
                              setSubmitting(false);
                              setActiveStepOne(false);
                              setActiveStepTwo(true);
                              window.$("html, body").animate(
                                {
                                  scrollTop: $("#groups-form").position().top,
                                },
                                "slow"
                              );
                            }}
                          >
                            {(props) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                /* y otras más */
                              } = props;
                              return (
                                <form
                                  id="step-1"
                                  onSubmit={handleSubmit}
                                  formNoValidate
                                >
                                  <div className="col-11 mx-auto">
                                    <div className="container mt-4 mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0">
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center">
                                          <img
                                            src={
                                              elements.usuario_img
                                                ? elements.usuario_img.value[0]
                                                    .url
                                                : ""
                                            }
                                            alt=""
                                            className="mr-2"
                                          />
                                          <div>
                                            <h5 className="font-weight-bold mb-0">
                                              {elements.form_user_title
                                                ? elements.form_user_title.value
                                                : ""}
                                            </h5>
                                            <h6 className="mb-0">
                                              {elements.form_user_subtitle
                                                ? elements.form_user_subtitle
                                                    .value
                                                : ""}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-4">
                                        <div className="col-sm-10 col-md-4 mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="firstname">
                                              {elements.form_user_input_name
                                                ? elements.form_user_input_name
                                                    .value
                                                : ""}
                                            </label>
                                            <Field
                                              className="form-control"
                                              name="firstname"
                                              id="firstname"
                                              type="text"
                                              aria-describedby="firstname"
                                              placeholder={
                                                elements.form_user_input_name_placeholder
                                                  ? elements
                                                      .form_user_input_name_placeholder
                                                      .value
                                                  : ""
                                              }
                                              validate={validateFirstname}
                                              value={values.firstname}
                                              onKeyPress={validateCampoLetras}
                                            />
                                            {errors.firstname &&
                                              touched.firstname && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.firstname}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md-4 mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="lastname">
                                              {elements.form_user_input_lastname
                                                ? elements
                                                    .form_user_input_lastname
                                                    .value
                                                : ""}
                                            </label>
                                            <Field
                                              className="form-control"
                                              name="lastname"
                                              id="lastname"
                                              type="text"
                                              aria-describedby="lastname"
                                              placeholder={
                                                elements.form_user_input_lastname_placeholder
                                                  ? elements
                                                      .form_user_input_lastname_placeholder
                                                      .value
                                                  : ""
                                              }
                                              validate={validateLastname}
                                              value={values.lastname}
                                              onKeyPress={validateCampoLetras}
                                            />
                                            {errors.lastname &&
                                              touched.lastname && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.lastname}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md-4 mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="email">
                                              {elements.form_user_input_email
                                                ? elements.form_user_input_email
                                                    .value
                                                : ""}
                                            </label>
                                            <Field
                                              className="form-control"
                                              name="email"
                                              id="email"
                                              type="email"
                                              aria-describedby="email"
                                              placeholder={
                                                elements.form_user_input_email_placeholder
                                                  ? elements
                                                      .form_user_input_email_placeholder
                                                      .value
                                                  : ""
                                              }
                                              validate={validateEmail}
                                              value={values.email}
                                              onKeyPress={validateCriterio}
                                            />
                                            {errors.email && touched.email && (
                                              <span className="text-danger text-small d-block mb-2">
                                                {errors.email}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="idType">
                                              {elements.form_user_input_document_type
                                                ? elements
                                                    .form_user_input_document_type
                                                    .value
                                                : ""}
                                            </label>
                                            <select
                                              className="form-control"
                                              id="idType"
                                              aria-describedby="idType"
                                              invalid={`"errors.idType && touched.idType"`}
                                              value={values.idType}
                                              onChange={(e) =>
                                                validateOnChangeEventTypeDoc(
                                                  e,
                                                  values,
                                                  handleChange
                                                )
                                              }
                                            >
                                              <option value="">
                                                {elements.form_user_input_document_type_placeholder
                                                  ? elements
                                                      .form_user_input_document_type_placeholder
                                                      .value
                                                  : ""}
                                              </option>
                                              {elements.form_user_input_document_type_group
                                                ? elements.form_user_input_document_type_group.value.map(
                                                    (option, index) => (
                                                      <option
                                                        key={index}
                                                        value={
                                                          modular[option]
                                                            ? modular[option]
                                                                .elements
                                                                .option_value
                                                                .value
                                                            : ""
                                                        }
                                                        selected={values.idType}
                                                      >
                                                        {modular[option]
                                                          ? modular[option]
                                                              .elements
                                                              .option_title
                                                              .value
                                                          : ""}
                                                      </option>
                                                    )
                                                  )
                                                : ""}
                                            </select>
                                            {errors.idType &&
                                              touched.idType && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.idType}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="idNum">
                                              {elements.form_user_input_document_number
                                                ? elements
                                                    .form_user_input_document_number
                                                    .value
                                                : ""}
                                            </label>
                                            <Field
                                              className="form-control"
                                              name="idNum"
                                              id="idNum"
                                              type="text"
                                              maxLength="15"
                                              aria-describedby="idNum"
                                              placeholder={
                                                elements.form_user_input_document_number_placeholder
                                                  ? elements
                                                      .form_user_input_document_number_placeholder
                                                      .value
                                                  : ""
                                              }
                                              validate={validateIDNumber}
                                              value={values.idNum}
                                              onKeyPress={validateNumDocAlfaNum}
                                            />
                                            {errors.idNum && touched.idNum && (
                                              <span className="text-danger text-small d-block mb-2">
                                                {errors.idNum}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="prefix">
                                              {elements.form_user_input_prefix
                                                ? elements
                                                    .form_user_input_prefix
                                                    .value
                                                : ""}
                                            </label>
                                            <select
                                              className="form-control"
                                              id="prefix"
                                              aria-describedby="prefix"
                                              invalid={`"errors.prefix && touched.prefix"`}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.prefix}
                                            >
                                              <option value="">
                                                {elements.form_user_input_prefix_placeholder
                                                  ? elements
                                                      .form_user_input_prefix_placeholder
                                                      .value
                                                  : ""}
                                              </option>
                                              {elements.form_user_input_prefix_group
                                                ? elements.form_user_input_prefix_group.value.map(
                                                    (option, index) => (
                                                      <option
                                                        key={index}
                                                        value={
                                                          modular[option]
                                                            ? modular[option]
                                                                .elements
                                                                .option_value
                                                                .value
                                                            : ""
                                                        }
                                                      >
                                                        {modular[option]
                                                          ? modular[option]
                                                              .elements
                                                              .option_title
                                                              .value
                                                          : ""}
                                                      </option>
                                                    )
                                                  )
                                                : ""}
                                            </select>
                                            {errors.prefix &&
                                              touched.prefix && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.prefix}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md-4 mx-auto ml-md-0 mt-3">
                                          <div className="form-group">
                                            <label htmlFor="phoneNumber">
                                              {elements.form_user_input_phone_number
                                                ? elements
                                                    .form_user_input_phone_number
                                                    .value
                                                : ""}
                                            </label>
                                            <Field
                                              className="form-control"
                                              name="phoneNumber"
                                              id="phoneNumber"
                                              type="text"
                                              maxLength="20"
                                              aria-describedby="phoneNumber"
                                              placeholder={
                                                elements.form_user_input_phone_number_placeholder
                                                  ? elements
                                                      .form_user_input_phone_number_placeholder
                                                      .value
                                                  : ""
                                              }
                                              validate={validatePhone}
                                              value={values.phoneNumber}
                                              onKeyPress={validatePhonePe}
                                            />
                                            {errors.phoneNumber &&
                                              touched.phoneNumber && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.phoneNumber}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </div>

                                      {cultura === "pe" && (
                                        <div className="row mt-4">
                                          <div className="col-sm-10 col-md-4 mt-3">
                                            <div className="form-group">
                                              <label htmlFor="address">
                                                {elements
                                                  .form_user_input_address
                                                  .value || ""}
                                              </label>
                                              <Field
                                                className="form-control"
                                                name="address"
                                                id="address"
                                                type="text"
                                                aria-describedby="address"
                                                placeholder={
                                                  elements
                                                    .form_user_input_address_placeholder
                                                    .value || ""
                                                }
                                                validate={validateAddress}
                                                value={values.address}
                                              />
                                              {errors.address &&
                                                touched.address && (
                                                  <span className="text-danger text-small d-block mb-2">
                                                    {errors.address}
                                                  </span>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      <div className="row mt-4">
                                        <div className="col-5 col-sm-4 col-md-3 col-lg-3 pl-0 ml-auto mt-auto">
                                          <button
                                            type="button"
                                            id="continueBtn1"
                                            className="continueBtn ml-auto"
                                            disabled={
                                              isSubmitting && !pnrInvalid
                                            }
                                            onClick={() => handleSubmit()}
                                          >
                                            {isSubmitting
                                              ? `${
                                                  elements.loading_button
                                                    ? elements.loading_button
                                                        .value
                                                    : ""
                                                }`
                                              : `${
                                                  elements.continue_button
                                                    ? elements.continue_button
                                                        .value
                                                    : ""
                                                }`}
                                            <i className="fal fa-chevron-circle-right pl-1 pl-sm-2"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              );
                            }}
                          </Formik>
                        </div>

                        <div
                          className={`banner ${
                            activeStepTwo
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }`}
                          id="questions-2"
                          role="tabpanel"
                          aria-labelledby="questions-2-tab"
                        >
                          <Formik
                            initialValues={{
                              requestType: "",
                              ruc: "",
                              city: "",
                              product: "",
                              amount: "",
                              description: "",
                              files: "",
                              pnr: "",
                              termsAndConditions: false,
                              termsAgeAndConditions: false,
                              nombrerppe: "",
                              domiciliorppe: "",
                              telefonorppe: "",
                              correorppe: "",
                              criteriaGds: "",
                              claimTitle: "",
                            }}
                            validate={(values) => {
                              let errors = {};

                              //We need a requestType
                              if (!values.requestType) {
                                errors.requestType =
                                  elements.form_service_input_type_of_request_error.value;
                              }

                              //We need a product
                              if (!values.product && cultura !== "pe") {
                                errors.product =
                                  elements.form_service_input_product_error.value;
                              }

                              //We need a claim title
                              if (!values.claimTitle && cultura === "pe") {
                                errors.claimTitle =
                                  elements.form_service_input_case_title_error
                                    .value || "";
                              }

                              //We need a pnr
                              if (!values.pnr && values.requestType === "R_") {
                                errors.pnr =
                                  elements.form_service_input_pnr_error.value;
                              }

                              if (
                                !values.criteriaGds &&
                                values.requestType === "R_"
                              ) {
                                errors.criteriaGds =
                                  elements.form_service_input_pnr_error.value;
                              }

                              //We need a RUC
                              if (
                                !values.ruc &&
                                cultura === "pe" &&
                                values.requestType === "R_"
                              ) {
                                errors.ruc =
                                  elements.form_service_input_case_description_error.value;
                              }

                              if (
                                !values.termsAndConditions &&
                                cultura === "pe" &&
                                values.requestType !== "R_"
                              ) {
                                errors.termsAndConditions =
                                  elements.form_service_input_terms_and_conditions_error.value;
                              }

                              if (values.termsAgeAndConditions) {
                                if (
                                  typeof values.telefonorppe === "undefined"
                                ) {
                                }

                                if (!values.nombrerppe && checkAge === true) {
                                  errors.nombrerppe =
                                    elements.form_service_input_pnr_error.value;
                                }
                                if (
                                  !values.domiciliorppe &&
                                  checkAge === true
                                ) {
                                  errors.domiciliorppe =
                                    elements.form_service_input_pnr_error.value;
                                }
                                if (
                                  (!values.telefonorppe ||
                                    typeof values.telefonorppe ===
                                      "undefined") &&
                                  checkAge === true
                                ) {
                                  errors.telefonorppe =
                                    elements.form_service_input_pnr_error.value;
                                }
                                if (!values.correorppe && checkAge === true) {
                                  errors.correorppe =
                                    elements.form_service_input_pnr_error.value;
                                }

                                //nombre
                                if (
                                  !onlyLettersRegExp.test(values.nombrerppe)
                                ) {
                                  errors.nombrerppe =
                                    elements.only_letters_error
                                      ? elements.only_letters_error.value
                                      : "";
                                }
                                // //direccion
                                if (
                                  !onlyLettersAndNumbersDireccionRegExp.test(
                                    values.domiciliorppe
                                  )
                                ) {
                                  errors.domiciliorppe =
                                    elements.only_letters_numbers_error
                                      ? elements.only_letters_numbers_error
                                          .value
                                      : "";
                                }
                                // //telefono
                                // if (!onlyNumbersRegExp.test(values.telefonorppe)) {
                                //   errors.telefonorppe = elements.only_numbers_error ? elements.only_numbers_error.value : '';
                                // }
                                //correo
                                if (!onlyMailRegExp.test(values.correorppe)) {
                                  errors.correorppe =
                                    elements.form_user_input_email_error_invalid
                                      ? elements
                                          .form_user_input_email_error_invalid
                                          .value
                                      : "";
                                }
                              }

                              return errors;

                              ///////////////////////////////////////////////////////////////////////////////////////////
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                              setSubmitting(true);
                              const recaptchaToken =
                                await recaptchaRef.current.executeAsync();

                              if (!pnrInvalid) {
                                let resultStepTwo = JSON.parse(
                                  JSON.stringify(values, null, 2)
                                );
                                let requestData = { ...data, ...resultStepTwo };
                                setData({ ...data, ...resultStepTwo });

                                requestData.product =
                                  cultura === "pe" && !requestData.product
                                    ? defaultProduct
                                    : requestData.product;
                                if (
                                  ambiente === "dev" ||
                                  ambiente === "cert" ||
                                  ambiente === "development" ||
                                  ambiente === "certification"
                                ) {
                                  requestData.requestType = "R_";
                                  requestData.product = defaultProduct;
                                }

                                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                //json DINSIDE / data
                                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                                let data2 = {
                                  channel: `${defaultChannel}${props.culture.country.toUpperCase()}`,
                                  type: defaultType, //fijo
                                  country:
                                    CodeCountry[props.culture.country] ||
                                    CodeCountry.defaultCode, //según cultura
                                  consulttype: defaultConsultType, //fijo
                                  submotive: submotiveData, //fijo - no enviar
                                  notificationclient: defaultNotification, //fijo
                                  pnr: requestData.pnr
                                    ? requestData.pnr
                                    : defaultPanel,
                                  firstname: requestData.firstname,
                                  lastname: requestData.lastname,
                                  email: requestData.email,
                                  phone:
                                    requestData.prefix +
                                    requestData.phoneNumber,
                                  address: requestData.address,
                                  dnitype: requestData.idType,
                                  idnum: requestData.idNum,
                                  requesttype:
                                    requestData.requestType + defaultArea, //area    (original)
                                  ruc:
                                    props.culture.country === "pe" ? ruc : "",
                                  jatCommercialAddress,
                                  city: requestData.city,
                                  product: requestData.product, //categoría    (original)
                                  amount: requestData.amount,
                                  description: requestData.description,
                                  files: documentation,
                                  termsandconditions:
                                    requestData.termsAndConditions,
                                  termsAgeAndConditions:
                                    requestData.termsAgeAndConditions,
                                  nombrerppe: requestData.nombrerppe
                                    ? requestData.nombrerppe
                                    : "",
                                  domiciliorppe: requestData.domiciliorppe
                                    ? requestData.domiciliorppe
                                    : "",
                                  telefonorppe: requestData.telefonorppe
                                    ? requestData.telefonorppe
                                    : "",
                                  correorppe: requestData.correorppe
                                    ? requestData.correorppe
                                    : "",
                                  datosBancarios: {
                                    pais: "",
                                    nombre_titular_cuenta: "",
                                    tipo_documento: "",
                                    n_documento: "",
                                    email_titular: "",
                                    nombre_banco: "",
                                    tipo_de_cuenta: "",
                                    n_cuenta_iban: "",
                                    cbu: "",
                                    n_swift: "",
                                    sucursal_agencia: "",
                                    direccion_fisica: "",
                                  },
                                  gds: isGdsPnr,
                                  tipoSolicitud: tipoSolicitud,
                                  tipoProducto,
                                  tipoDocumento: defaultDocTypeLabel || "",

                                  disponible2: vueloConConexion,
                                  disponible3: requestData.address,
                                  comentarios: `${requestData.claimTitle} ${requestData.description}`,
                                  recaptchaToken,
                                };

                                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                                let loading = window.newRtLoader(".loading", {
                                  logo_color: "white", // white or colored
                                  background_color: "rgba(255,255,255,.97)", // any color in hex or rgb/rgba
                                  airplane_color: "#163a70", // any color in hex
                                  dots_color: "#163a70", // any color in hex
                                });

                                window
                                  .$("html, body")
                                  .animate(
                                    { scrollTop: $("#divcenter").offset().top },
                                    "slow"
                                  );
                                loading.startLoader();

                                claim
                                  .createClaim(data2)
                                  .then((response) => {
                                    let currentDate = getDate();
                                    setAuxiliarData({ ...data2, currentDate });
                                    setNumberCase(
                                      response.data.data.case_number
                                    );
                                    setIdInterno(
                                      formateaIdInterno(
                                        response.data.data.id_interno
                                      )
                                    );

                                    setActiveStepTwo(false);
                                    setActiveStepThree(true);
                                    setSubmitting(false);
                                    loading.stopLoader();
                                  })
                                  .catch((err) => {
                                    console.log(err); //setShowModal(true);
                                    setSubmitting(false);
                                    setMessageModal(
                                      elements.error_message_claim
                                        ? elements.error_message_claim.value
                                        : ""
                                    );
                                    /* Activa Modal */
                                    window.$("#dev_modal").modal("show");
                                    loading.stopLoader();
                                  });
                              } else {
                                setSubmitting(false);
                                //setShowModal(true);
                                if (errorCaptcha) {
                                  setMessageModal(
                                    elements.recaptcha_error
                                      ? elements.recaptcha_error.value
                                      : ""
                                  );
                                }
                                if (pnrInvalid) {
                                  setMessageModal(
                                    elements.error_message_general
                                      ? elements.error_message_general.value
                                      : ""
                                  );
                                }
                                /* Activa Modal */
                                window.$("#dev_modal").modal("show");
                              }
                            }}
                          >
                            {(props) => {
                              const {
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                setFieldTouched,
                                setErrors,
                                resetForm,
                                /* y otras más */
                              } = props;
                              return (
                                <form
                                  id="step-2"
                                  onSubmit={handleSubmit}
                                  formNoValidate
                                >
                                  <div className="col-11 mx-auto">
                                    <div className="container mt-4 mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0">
                                      <div className="row">
                                        <div className="col-12 d-flex align-items-center">
                                          <img
                                            src={
                                              elements.servicio_img
                                                ? elements.servicio_img.value[0]
                                                    .url
                                                : ""
                                            }
                                            alt=""
                                            className="mr-2"
                                          />
                                          <div>
                                            <h5 className="font-weight-bold mb-0">
                                              {elements.form_service_title
                                                ? elements.form_service_title
                                                    .value
                                                : ""}
                                            </h5>
                                            <h6 className="mb-0">
                                              {elements.form_service_subtitle
                                                ? elements.form_service_subtitle
                                                    .value
                                                : ""}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row mt-4">
                                        <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                          <div
                                            className="form-group"
                                            id="divcenter"
                                          >
                                            <label htmlFor="requestType">
                                              {elements.form_service_input_type_of_request
                                                ? elements
                                                    .form_service_input_type_of_request
                                                    .value
                                                : ""}
                                            </label>
                                            <select
                                              className="form-control"
                                              id="requestType"
                                              aria-describedby="requestType"
                                              invalid={`"errors.requestType"`}
                                              onChange={(e) =>
                                                validateOnChangeEvent(
                                                  e,
                                                  values,
                                                  handleChange
                                                )
                                              }
                                              onBlur={handleBlur}
                                              value={values.requestType}
                                            >
                                              <option value="">
                                                {elements.form_service_input_type_of_request_placeholder
                                                  ? elements
                                                      .form_service_input_type_of_request_placeholder
                                                      .value
                                                  : ""}
                                              </option>
                                              {elements.form_service_input_type_of_request_group
                                                ? elements.form_service_input_type_of_request_group.value.map(
                                                    (option, index) => (
                                                      <option
                                                        key={index}
                                                        value={
                                                          modular[option]
                                                            ? modular[option]
                                                                .elements
                                                                .option_value
                                                                .value
                                                            : ""
                                                        }
                                                      >
                                                        {modular[option]
                                                          ? modular[option]
                                                              .elements
                                                              .option_title
                                                              .value
                                                          : ""}
                                                      </option>
                                                    )
                                                  )
                                                : ""}
                                            </select>
                                            {errors.requestType && (
                                              <span className="text-danger text-small d-block mb-2">
                                                {errors.requestType}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                          {showRuc ? (
                                            <div className="form-group">
                                              <label htmlFor="ruc">
                                                {elements.form_service_input_business_name
                                                  ? elements
                                                      .form_service_input_business_name
                                                      .value
                                                  : ""}
                                              </label>
                                              <select
                                                className="form-control"
                                                id="ruc"
                                                aria-describedby="ruc"
                                                invalid={`"errors.ruc &&`}
                                                onChange={(e) =>
                                                  validateOnChangeRuc(
                                                    e,
                                                    values,
                                                    handleChange
                                                  )
                                                }
                                                validate={validateRucField}
                                                onBlur={handleBlur}
                                                value={values.ruc}
                                              >
                                                <option value="">
                                                  {elements.form_service_input_business_name_placeholder
                                                    ? elements
                                                        .form_service_input_business_name_placeholder
                                                        .value
                                                    : ""}
                                                </option>
                                                {elements.form_service_input_business_name_group
                                                  ? elements.form_service_input_business_name_group.value.map(
                                                      (option, index) => (
                                                        <option
                                                          key={index}
                                                          value={
                                                            modular[option]
                                                              ? modular[option]
                                                                  .elements
                                                                  .option_value
                                                                  .value
                                                              : ""
                                                          }
                                                        >
                                                          {modular[option]
                                                            ? modular[option]
                                                                .elements
                                                                .option_title
                                                                .value
                                                            : ""}
                                                        </option>
                                                      )
                                                    )
                                                  : ""}
                                              </select>
                                              {touched.ruc && errors.ruc && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.ruc}
                                                </span>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>

                                        {/* ////////////////////////////////////////////city nuevo */}

                                        <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                          {cultura === "pe" ? (
                                            <div className="form-group">
                                              <label htmlFor="city">
                                                {elements.form_service_input_ciudad_identification
                                                  ? elements
                                                      .form_service_input_ciudad_identification
                                                      .value
                                                  : ""}
                                              </label>

                                              <Field
                                                className="form-control"
                                                name="city"
                                                id="city"
                                                type="text"
                                                aria-describedby="city"
                                                placeholder={
                                                  elements.form_service_input_ciudad_identification_placehol
                                                    ? elements
                                                        .form_service_input_ciudad_identification_placehol
                                                        .value
                                                    : ""
                                                }
                                                validate={validateCity}
                                                value={values.city}
                                                onKeyPress={validateCampoLetras}
                                                onBlur={(e) =>
                                                  validaCity(
                                                    values,
                                                    setFieldValue,
                                                    setFieldTouched
                                                  )
                                                }
                                              />
                                              {errors.city && touched.city && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.city}
                                                </span>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                        <div className="col-sm-10 col-md-6 mx-auto mt-3" />

                                        <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="product">
                                              {elements.form_service_input_product_identification
                                                ? elements
                                                    .form_service_input_product_identification
                                                    .value
                                                : ""}
                                            </label>
                                            <select
                                              className="form-control"
                                              id="product"
                                              aria-describedby="product"
                                              invalid={`"errors.product`}
                                              onChange={(e) =>
                                                validateOnProductChange(
                                                  e,
                                                  values,
                                                  handleChange
                                                )
                                              }
                                              onBlur={handleBlur}
                                              value={values.product}
                                            >
                                              <option value="">
                                                {elements.form_service_input_product_identification_placehol
                                                  ? elements
                                                      .form_service_input_product_identification_placehol
                                                      .value
                                                  : ""}
                                              </option>
                                              {elements.form_service_input_product_identification_group
                                                ? elements.form_service_input_product_identification_group.value.map(
                                                    (option, index) =>
                                                      values.requestType ===
                                                        "F_" &&
                                                      modular[option].elements
                                                        .option_value.value !==
                                                        defaultProductOtro ? (
                                                        ""
                                                      ) : (
                                                        <option
                                                          key={index}
                                                          value={
                                                            modular[option]
                                                              ? modular[option]
                                                                  .elements
                                                                  .option_value
                                                                  .value
                                                              : ""
                                                          }
                                                        >
                                                          {modular[option]
                                                            ? modular[option]
                                                                .elements
                                                                .option_title
                                                                .value
                                                            : ""}
                                                        </option>
                                                      )
                                                  )
                                                : ""}
                                            </select>
                                            {touched.product &&
                                              errors.product && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.product}
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                          {showAmount ? (
                                            <div className="form-group">
                                              <label htmlFor="amount">
                                                {elements.form_service_input_amount_of_service_claimed
                                                  ? elements
                                                      .form_service_input_amount_of_service_claimed
                                                      .value
                                                  : ""}
                                              </label>
                                              <Field
                                                name="amount"
                                                validate={validateAmount}
                                              >
                                                {(field) => (
                                                  <CurrencyInput
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    id="amount"
                                                    value={values.amount}
                                                    placeholder={
                                                      elements.form_service_input_amount_of_service_claimed_place
                                                        ? elements
                                                            .form_service_input_amount_of_service_claimed_place
                                                            .value
                                                        : ""
                                                    }
                                                    type="text"
                                                  />
                                                )}
                                              </Field>
                                              {touched.amount &&
                                                errors.amount && (
                                                  <span className="text-danger text-small d-block mb-2">
                                                    {errors.amount}
                                                  </span>
                                                )}
                                            </div>
                                          ) : null}
                                        </div>

                                        {cultura === "pe" && (
                                          <>
                                            <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                              <div className="form-group">
                                                <label htmlFor="claimTitle">
                                                  {elements
                                                    .form_service_input_case_title
                                                    .value || ""}
                                                </label>

                                                <Field
                                                  className="form-control"
                                                  name="claimTitle"
                                                  id="claimTitle"
                                                  type="text"
                                                  aria-describedby="claimTitle"
                                                  placeholder={
                                                    elements
                                                      .form_service_input_case_title_placeholder
                                                      .value || ""
                                                  }
                                                  validate={validateClaimTitle}
                                                  value={values.claimTitle}
                                                  onKeyPress={
                                                    validateCampoLetras
                                                  }
                                                  maxLength={
                                                    maxClaimTitleLength
                                                  }
                                                />
                                                {errors.claimTitle &&
                                                  touched.claimTitle && (
                                                    <span className="text-danger text-small d-block mb-2">
                                                      {errors.claimTitle}
                                                    </span>
                                                  )}
                                                {values.claimTitle.length >=
                                                  50 && (
                                                  <span className="text-danger text-small d-block mb-2">
                                                    {errorDescriptionExceeded}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                            <div className="col-sm-10 col-md-6 mx-auto mt-3" />
                                          </>
                                        )}

                                        <div className="col-sm-10 col-md-12 mx-auto mt-3">
                                          <div className="form-group">
                                            <label htmlFor="description">
                                              {elements.form_service_input_case_description
                                                ? elements
                                                    .form_service_input_case_description
                                                    .value
                                                : ""}
                                            </label>
                                            <span
                                              style={{
                                                float: "right",
                                                fontSize: "9pt",
                                              }}
                                            >
                                              {countDescription}/
                                              {maxDescriptionLength}
                                            </span>
                                            <Field
                                              className="form-control"
                                              name="description"
                                              id="description"
                                              as="textarea"
                                              aria-describedby="description"
                                              placeholder={
                                                elements.form_service_input_case_description_placeholder
                                                  ? elements
                                                      .form_service_input_case_description_placeholder
                                                      .value
                                                  : ""
                                              }
                                              value={values.description}
                                              validate={validateDescription}
                                              maxLength={maxDescriptionLength}
                                            ></Field>
                                            {touched.description &&
                                              errors.description && (
                                                <span className="text-danger text-small d-block mb-2">
                                                  {errors.description}
                                                </span>
                                              )}
                                            {descriptionExceeded ? (
                                              <span className="text-danger text-small d-block mb-2">
                                                {errorDescriptionExceeded}
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="row" ref={mainRef}>
                                          <div className="col-12">
                                            <div className="warning-text">
                                              <img
                                                src={
                                                  elements.reclamo_img
                                                    ? elements.reclamo_img
                                                        .value[0].url
                                                    : ""
                                                }
                                                alt=""
                                                className="mr-3"
                                              />
                                              <div>
                                                <p className="mb-0">
                                                  <strong>
                                                    {elements.form_service_title_claim
                                                      ? elements
                                                          .form_service_title_claim
                                                          .value
                                                      : ""}
                                                  </strong>{" "}
                                                  {elements.form_service_title_claim
                                                    ? elements
                                                        .form_service_content_claim
                                                        .value
                                                    : ""}
                                                  <br />
                                                  <strong>
                                                    {elements.form_service_title_complain
                                                      ? elements
                                                          .form_service_title_complain
                                                          .value
                                                      : ""}
                                                  </strong>{" "}
                                                  {elements.form_service_content_complain
                                                    ? elements
                                                        .form_service_content_complain
                                                        .value
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="row mt-4"
                                          ref={groupCriterioRef}
                                          id="div1"
                                        >
                                          <div className="col-12 d-flex align-items-center">
                                            <img
                                              src={
                                                elements.servicio_img
                                                  ? elements.servicio_img
                                                      .value[0].url
                                                  : ""
                                              }
                                              alt=""
                                              className="mr-2"
                                            />
                                            <div>
                                              <h5 className="font-weight-bold mb-0">
                                                {elements.form_service_section_documentation_title
                                                  ? elements
                                                      .form_service_section_documentation_title
                                                      .value
                                                  : ""}
                                              </h5>
                                              <h6 className="mb-0">
                                                {elements.form_service_section_documentation_subtitle
                                                  ? elements
                                                      .form_service_section_documentation_subtitle
                                                      .value
                                                  : ""}
                                              </h6>
                                            </div>
                                          </div>
                                          <div className="col-12 mt-4">
                                            <input
                                              type="file"
                                              id="filesToUpload"
                                              multiple
                                              name="files"
                                              hidden
                                              accept="application/pdf, image/jpeg, image/png, image/jpg"
                                              invalid={`"errors.files`}
                                              onChange={handleFileFieldChange}
                                              onBlur={handleBlur}
                                              value={values.files}
                                            />
                                            <button
                                              type="button"
                                              id="upload"
                                              className="attachBtn"
                                              onClick={() =>
                                                document
                                                  .getElementById(
                                                    "filesToUpload"
                                                  )
                                                  .click()
                                              }
                                            >
                                              <img
                                                src={
                                                  elements.adjuntar_img
                                                    ? elements.adjuntar_img
                                                        .value[0].url
                                                    : ""
                                                }
                                                className="mr-2 original-icon"
                                                alt=""
                                              />
                                              <img
                                                src={
                                                  elements.adjunto_hover_img
                                                    ? elements.adjunto_hover_img
                                                        .value[0].url
                                                    : ""
                                                }
                                                className="mr-2 hover-icon"
                                                alt=""
                                              />
                                              {elements.form_service_section_documentation_input_attach
                                                ? elements
                                                    .form_service_section_documentation_input_attach
                                                    .value
                                                : ""}
                                            </button>
                                          </div>
                                          <div className="col-12 mt-3">
                                            <p>
                                              {elements.form_service_section_documentation_count_attach
                                                ? elements
                                                    .form_service_section_documentation_count_attach
                                                    .value
                                                : ""}{" "}
                                              <span
                                                id="files-count"
                                                className="font-weight-bold"
                                              >
                                                {documentation.length}
                                              </span>
                                            </p>
                                            <ul id="files-list">
                                              {attachFile.map((file, index) => (
                                                <li key={index}>
                                                  {file}
                                                  <a
                                                    href="!#"
                                                    onClick={(e) =>
                                                      removeElementFromAttachments(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-times"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#ac272f",
                                                        paddingLeft: "9px",
                                                      }}
                                                    ></i>
                                                  </a>
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="row mt-4">
                                          <div className="col-12 d-flex align-items-center">
                                            <img
                                              src={
                                                elements.servicio_img
                                                  ? elements.servicio_img
                                                      .value[0].url
                                                  : ""
                                              }
                                              alt=""
                                              className="mr-2"
                                              hidden={
                                                values.requestType !== "R_"
                                              }
                                            />
                                            <div
                                              hidden={
                                                values.requestType !== "R_"
                                              }
                                            >
                                              <h5 className="font-weight-bold mb-0">
                                                {elements.form_service_section_flight_title
                                                  ? elements
                                                      .form_service_section_flight_title
                                                      .value
                                                  : ""}
                                              </h5>
                                              <h6 className="mb-0">
                                                {elements.form_service_section_flight_subtitle
                                                  ? elements
                                                      .form_service_section_flight_subtitle
                                                      .value
                                                  : ""}
                                              </h6>
                                            </div>
                                          </div>

                                          <div
                                            className="col-12 mt-4"
                                            hidden={values.requestType !== "R_"}
                                          >
                                            {/* ///////////////////////////// nueva validacion de GDS mediante apellido y correo */}
                                            <div className="row mt-4">
                                              <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                                <div className="form-group">
                                                  <label htmlFor="criteriaGds">
                                                    {elements.form_user_input_email
                                                      ? elements
                                                          .form_user_input_email
                                                          .value
                                                      : ""}
                                                  </label>
                                                  <Field
                                                    className="form-control"
                                                    name="criteriaGds"
                                                    id="criteriaGds"
                                                    type="text"
                                                    aria-describedby="criteriaGds"
                                                    placeholder={
                                                      elements.form_user_input_email_placeholder
                                                        ? elements
                                                            .form_user_input_email_placeholder
                                                            .value
                                                        : ""
                                                    }
                                                    value={values.criteriaGds}
                                                    onBlur={(e) =>
                                                      handlePnrFieldCriterio(
                                                        e,
                                                        setFieldValue,
                                                        values
                                                      )
                                                    }
                                                    onKeyPress={
                                                      validateCriterio
                                                    }
                                                    onPaste={(e) =>
                                                      validateCriterioPaste(
                                                        e,
                                                        handleChange
                                                      )
                                                    }
                                                  />
                                                  {touched.criteriaGds &&
                                                    errors.criteriaGds && (
                                                      <span className="text-danger text-small d-block mb-2">
                                                        {errors.criteriaGds}
                                                      </span>
                                                    )}
                                                </div>
                                              </div>
                                              <div className="col-sm-10 col-md-6 mx-auto mt-3">
                                                <div className="form-group">
                                                  <label htmlFor="pnr">
                                                    {elements.form_service_section_flight_input_pnr
                                                      ? elements
                                                          .form_service_section_flight_input_pnr
                                                          .value
                                                      : ""}
                                                  </label>
                                                  <Field
                                                    className="form-control"
                                                    name="pnr"
                                                    id="pnr"
                                                    type="text"
                                                    maxLength="6"
                                                    aria-describedby="pnr"
                                                    placeholder={
                                                      elements.form_service_section_flight_input_pnr_placeholder
                                                        ? elements
                                                            .form_service_section_flight_input_pnr_placeholder
                                                            .value
                                                        : ""
                                                    }
                                                    value={values.pnr}
                                                    onChange={(e) =>
                                                      handlePnrFieldChange(
                                                        e,
                                                        values,
                                                        handleChange,
                                                        setFieldValue
                                                      )
                                                    }
                                                    onBlur={(e) =>
                                                      handlePnrFieldBlur(
                                                        e,
                                                        values,
                                                        handleChange
                                                      )
                                                    }
                                                    disabled={pnrGdsDisabled}
                                                    onKeyPress={validatePnr}
                                                    onPaste={(e) =>
                                                      validatePnrPaste(
                                                        e,
                                                        handleChange
                                                      )
                                                    }
                                                  />
                                                  {touched.pnr &&
                                                    errors.pnr && (
                                                      <span className="text-danger text-small d-block mb-2">
                                                        {errors.pnr}
                                                      </span>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          {/* ///////////////////////////// modificacion validacion de pnr AA o JS SFD-2636 */}

                                          {esVueloAA === true ? (
                                            <div className="col-12">
                                              <div className="form-group">
                                                <label htmlFor="validaAAJS">
                                                  {elements.reclamos_js_aa
                                                    ? elements.reclamos_js_aa
                                                        .value
                                                    : ""}
                                                </label>
                                              </div>
                                              <div className="form-group col-12">
                                                <div>
                                                  <div className="custom-control custom-radio custom-control-inline">
                                                    <label className=".margin-left_radiobutton">
                                                      <input
                                                        type="radio"
                                                        value="pnr_js"
                                                        checked={
                                                          selectedOption ===
                                                          "pnr_js"
                                                        }
                                                        onChange={
                                                          handleOptionChangeAA
                                                        }
                                                      />
                                                      {elements.company_name_js
                                                        ? elements
                                                            .company_name_js
                                                            .value
                                                        : ""}
                                                    </label>
                                                  </div>
                                                  <div className="custom-control custom-radio custom-control-inline">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="pnr_aa"
                                                        checked={
                                                          selectedOption ===
                                                          "pnr_aa"
                                                        }
                                                        onChange={
                                                          handleOptionChangeAA
                                                        }
                                                      />
                                                      {elements.company_name_aa
                                                        ? elements
                                                            .company_name_aa
                                                            .value
                                                        : ""}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}

                                          {cultura === "pe" ? (
                                            <div className="col-12">
                                              <div className="termsAgeCheckbox">
                                                <Field
                                                  className="custom-control-input"
                                                  name="termsAgeAndConditions"
                                                  id="termsAgeAndConditions"
                                                  type="checkbox"
                                                  aria-describedby="tyc"
                                                  aria-label="tyc"
                                                  validate={
                                                    validateTermsAndConditionsAge
                                                  }
                                                  onClick={(e) => {
                                                    handleChange(e);
                                                    setFieldValue(
                                                      "nombrerppe",
                                                      limpiarCamposMenorDeEdad(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFieldValue(
                                                      "domiciliorppe",
                                                      limpiarCamposMenorDeEdad(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFieldValue(
                                                      "telefonorppe",
                                                      limpiarCamposMenorDeEdad(
                                                        e.target.value
                                                      )
                                                    );
                                                    setFieldValue(
                                                      "correorppe",
                                                      limpiarCamposMenorDeEdad(
                                                        e.target.value
                                                      )
                                                    );
                                                    // setErrors({})
                                                    setFieldTouched(
                                                      "nombrerppe",
                                                      false,
                                                      false
                                                    );
                                                    setFieldTouched(
                                                      "domiciliorppe",
                                                      false,
                                                      false
                                                    );
                                                    setFieldTouched(
                                                      "telefonorppe",
                                                      false,
                                                      false
                                                    );
                                                    setFieldTouched(
                                                      "correorppe",
                                                      false,
                                                      false
                                                    );
                                                  }}
                                                />
                                                <label
                                                  className="custom-control-label group-radio-text"
                                                  htmlFor="termsAgeAndConditions"
                                                >
                                                  {elements.menor_edad.value[0]
                                                    ? elements.menor_edad
                                                        .value[0].name
                                                    : ""}
                                                  {/* {elements.form_service_input_terms_and_conditions ? parse(elements.form_service_input_terms_and_conditions.value, { trim: true }) : '' } */}
                                                </label>
                                                {errors.termsAgeAndConditions && (
                                                  <span className="text-danger text-small d-block mb-2">
                                                    {
                                                      errors.termsAgeAndConditions
                                                    }
                                                  </span>
                                                )}
                                              </div>
                                              <div
                                                id="errorTC"
                                                className="error-message pt-3 ml-4"
                                              ></div>
                                            </div>
                                          ) : null}

                                          <div className="col-10 mt-0">
                                            {checkAge ? (
                                              <div className="row mt-4">
                                                {/* <div className="col-sm-8 col-md-20 mx-auto mt-0"> */}
                                                <div className="col-sm-8 col-md-20  mt-0">
                                                  <div className="form-group">
                                                    <label htmlFor="nombrerppe">
                                                      {/* Nombre completo de uno de sus padres o representantes */}
                                                      {elements
                                                        .form_user_input_nombre_representantes
                                                        .value
                                                        ? elements
                                                            .form_user_input_nombre_representantes
                                                            .value
                                                        : ""}
                                                    </label>
                                                    <Field
                                                      className="form-control"
                                                      name="nombrerppe"
                                                      id="nombrerppe"
                                                      type="text"
                                                      aria-describedby="nombrerppe"
                                                      placeholder={
                                                        elements
                                                          .form_user_input_nombre_representantes_placeholder
                                                          .value
                                                          ? elements
                                                              .form_user_input_nombre_representantes_placeholder
                                                              .value
                                                          : ""
                                                      }
                                                      value={values.nombrerppe}
                                                      onKeyPress={
                                                        validateCampoLetras
                                                      }
                                                    />
                                                    {/* {errors.nombrerppe && touched.nombrerppe && ( */}
                                                    {errors.nombrerppe && (
                                                      // touched.nombrerppe &&
                                                      <span className="text-danger text-small d-block mb-2">
                                                        {errors.nombrerppe}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="form-group">
                                                    <label htmlFor="domiciliorppe">
                                                      {/* Domicilio de uno de sus padres o representante */}
                                                      {elements
                                                        .form_user_input_domicilio_representantes
                                                        .value
                                                        ? elements
                                                            .form_user_input_domicilio_representantes
                                                            .value
                                                        : ""}
                                                    </label>
                                                    <Field
                                                      className="form-control"
                                                      name="domiciliorppe"
                                                      id="domiciliorppe"
                                                      type="text"
                                                      aria-describedby="domiciliorppe"
                                                      placeholder={
                                                        elements
                                                          .form_user_input_domicilio_representantes_placehold
                                                          .value
                                                          ? elements
                                                              .form_user_input_domicilio_representantes_placehold
                                                              .value
                                                          : ""
                                                      }
                                                      value={
                                                        values.domiciliorppe
                                                      }
                                                      onKeyPress={
                                                        validateCampoLetrasNumeros
                                                      }
                                                    />
                                                    {errors.domiciliorppe && (
                                                      // touched.domiciliorppe &&
                                                      <span className="text-danger text-small d-block mb-2">
                                                        {errors.domiciliorppe}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="form-group">
                                                    <label htmlFor="telefonorppe">
                                                      {/* Telefono de uno de sus padres o representantes */}
                                                      {elements
                                                        .form_user_input_telefono_representantes
                                                        .value
                                                        ? elements
                                                            .form_user_input_telefono_representantes
                                                            .value
                                                        : ""}
                                                    </label>
                                                    <Field
                                                      className="form-control"
                                                      name="telefonorppe"
                                                      id="telefonorppe"
                                                      type="number"
                                                      aria-describedby="telefonorppe"
                                                      placeholder={
                                                        elements
                                                          .form_user_input_telefono_representante_placeholder
                                                          .value
                                                          ? elements
                                                              .form_user_input_telefono_representante_placeholder
                                                              .value
                                                          : ""
                                                      }
                                                      value={
                                                        values.telefonorppe
                                                      }
                                                      onKeyPress={
                                                        validatePhonePe
                                                      }
                                                    />
                                                    {errors.telefonorppe && (
                                                      // touched.telefonorppe &&
                                                      <span className="text-danger text-small d-block mb-2">
                                                        {errors.telefonorppe}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <div className="form-group">
                                                    <label htmlFor="correorppe">
                                                      {/* Correo electrónico de uno de sus padres o representantes */}
                                                      {elements
                                                        .form_user_input_correo_representantes
                                                        .value
                                                        ? elements
                                                            .form_user_input_correo_representantes
                                                            .value
                                                        : ""}
                                                    </label>
                                                    <Field
                                                      className="form-control"
                                                      name="correorppe"
                                                      id="correorppe"
                                                      type="email"
                                                      aria-describedby="correorppe"
                                                      placeholder={
                                                        elements
                                                          .orm_user_input_correo_representantes_placeholder
                                                          .value
                                                          ? elements
                                                              .orm_user_input_correo_representantes_placeholder
                                                              .value
                                                          : ""
                                                      }
                                                      validate={validateEmail}
                                                      value={values.correorppe}
                                                    />
                                                    {errors.correorppe && (
                                                      // touched.correorppe &&
                                                      <span className="text-danger text-small d-block mb-2">
                                                        {errors.correorppe}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>

                                          <div className="col-12">
                                            <div className="termsCheckbox">
                                              <Field
                                                className="custom-control-input"
                                                name="termsAndConditions"
                                                id="termsAndConditions"
                                                type="checkbox"
                                                aria-describedby="tyc"
                                                aria-label="tyc"
                                                validate={(e) =>
                                                  validateTermsAndConditions(
                                                    e,
                                                    values
                                                  )
                                                }
                                                value={
                                                  values.termsAndConditions
                                                }
                                                checked={
                                                  values.termsAndConditions
                                                }
                                              />
                                              <label
                                                className="custom-control-label group-radio-text"
                                                htmlFor="termsAndConditions"
                                              >
                                                <span
                                                  style={{ display: "inline" }}
                                                >
                                                  {parse(
                                                    elements.form_service_input_terms_and_conditions.value.replace(
                                                      /<\/?p>/g,
                                                      ""
                                                    )
                                                  )}
                                                </span>
                                                {cultura === "pe" &&
                                                  values.requestType ===
                                                    "R_" && (
                                                    <span
                                                      style={{
                                                        display: "inline",
                                                      }}
                                                    >
                                                      {` ${elements.form_service_input_terms_and_conditions_optional.value}`}
                                                    </span>
                                                  )}
                                              </label>
                                              {touched.termsAndConditions &&
                                                errors.termsAndConditions && (
                                                  <span className="text-danger text-small d-block mb-2">
                                                    {errors.termsAndConditions}
                                                  </span>
                                                )}
                                            </div>
                                            <div
                                              id="errorTC"
                                              className="error-message pt-3 ml-4"
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={
                                          process.env.REACT_APP_KEY_RECAPTCHA
                                        }
                                        size="invisible"
                                      />

                                      <div className="row mt-4">
                                        <div className="col-5 col-md-3 col-lg-2 pr-0 mr-auto">
                                          <button
                                            type="button"
                                            id="backBtn1"
                                            className="backBtn mr-auto"
                                            onClick={backAction}
                                          >
                                            <i className="fal fa-chevron-circle-left pr-1 pr-sm-2"></i>
                                            {elements.back_button
                                              ? elements.back_button.value
                                              : ""}
                                          </button>
                                        </div>
                                        <div className="col-5 col-sm-4 col-md-3 col-lg-3 pl-0 ml-auto">
                                          <button
                                            type="submit"
                                            id="continueBtn2"
                                            className="continueBtn ml-auto"
                                            disabled={isSubmitting}
                                            onClick={(e) =>
                                              validaFillUp(
                                                e,
                                                values,
                                                cultura,
                                                setFieldValue
                                              )
                                            }
                                          >
                                            {isSubmitting
                                              ? `${
                                                  elements.loading_button
                                                    ? elements.loading_button
                                                        .value
                                                    : ""
                                                }`
                                              : `${
                                                  elements.continue_button
                                                    ? elements.continue_button
                                                        .value
                                                    : ""
                                                }`}
                                            <i className="fal fa-chevron-circle-right pl-1 pl-sm-2"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              );
                            }}
                          </Formik>
                        </div>

                        <div
                          className={`banner ${
                            activeStepThree
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }`}
                          id="questions-3"
                          role="tabpanel"
                          aria-labelledby="questions-3-tab"
                        >
                          <div className="col-11 mx-auto">
                            <div className="container mt-4 mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3 mb-0">
                              <div className="row">
                                <div
                                  className="col-12 text-center"
                                  id="form-confirmation"
                                >
                                  <div className="row">
                                    <div className="col-11 col-sm-9 col-md-8 mx-auto text-center mt-3 display-2">
                                      <i className="fas fa-check-circle"></i>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-11 col-sm-9 col-md-8 mx-auto mt-3">
                                      <h3 className="font-weight-bold">
                                        {elements.confirm_title
                                          ? elements.confirm_title.value
                                          : ""}
                                      </h3>
                                      <h5>
                                        {elements.confirm_subtitle
                                          ? elements.confirm_subtitle.value
                                          : ""}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-11 col-sm-9 col-md-8 mx-auto mt-3 color-light-blue-js">
                                      <h5>
                                        {elements.number_case_title
                                          ? elements.number_case_title.value
                                          : ""}
                                      </h5>
                                      <h3 className="font-weight-bold">
                                        {numberCase}
                                      </h3>
                                      <h6 className="font-weight-bold id_interno">
                                        {/* ID Interno :  */}
                                        {elements.id_caso.value
                                          ? elements.id_caso.value
                                          : ""}
                                        &nbsp;:&nbsp;
                                        {idInterno}
                                      </h6>
                                    </div>
                                  </div>

                                  {cultura !== "br" && cultura !== "us" ? (
                                    <div className="row">
                                      <div className="mx-auto">
                                        <button
                                          type="button"
                                          id="continueBtn1"
                                          className="continueBtn"
                                          onClick={() =>
                                            handleGeneratePdf(true)
                                          }
                                        >
                                          {/* Imprimir */}
                                          {elements.imprimir_button.value
                                            ? elements.imprimir_button.value
                                            : ""}
                                          <i className="fal fa-chevron-circle-right pl-1 pl-sm-2"></i>
                                        </button>
                                      </div>
                                    </div>
                                  ) : null}

                                  {/* insertar formulario de envio de email */}

                                  {cultura === "pe" ? (
                                    <form onSubmit={enviarMail}>
                                      <div className="row">
                                        <div className="mx-auto">
                                          <table aling="center">
                                            <tr>
                                              <td colspan="2">
                                                <h6>
                                                  {elements.form_user_send_email_explicative_text
                                                    ? elements
                                                        .form_user_send_email_explicative_text
                                                        .value
                                                    : "No hay kentico aun"}
                                                </h6>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <input
                                                  name="email"
                                                  id="email"
                                                  type="text"
                                                  className="form-control align-bottom"
                                                  aria-describedby="email"
                                                  placeholder={
                                                    elements
                                                      .form_user_input_correo_placehold
                                                      .value
                                                      ? elements
                                                          .form_user_input_correo_placehold
                                                          .value
                                                      : ""
                                                  }
                                                  onChange={limpiarError}
                                                  onKeyPress={limpiarErrorMas}
                                                  onPaste={(e) =>
                                                    validatePasteEmail(e)
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <button
                                                  type="submit"
                                                  id="continueBtn1"
                                                  className="align-bottom continueBtn"
                                                >
                                                  {elements.button_enviar_email
                                                    .value
                                                    ? elements
                                                        .button_enviar_email
                                                        .value
                                                    : ""}
                                                  <i className="fal fa-chevron-circle-right pl-1 pl-sm-2"></i>
                                                </button>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="text-danger">
                                                  {errorEmail}
                                                </span>
                                              </td>
                                              <td></td>
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                    </form>
                                  ) : null}

                                  {/* ///////////////////////////////////////////// */}

                                  <div className="row">
                                    <div className="col-11 col-sm-9 col-md-8 mx-auto mt-2">
                                      <h6>
                                        {elements.number_case_subtitle
                                          ? elements.number_case_subtitle.value
                                          : ""}
                                      </h6>
                                      <img
                                        src={
                                          elements.js_img
                                            ? elements.js_img.value[0].url
                                            : ""
                                        }
                                        alt="equipo JetSMART"
                                        className="mt-4"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-12 text-center px-0 mx-auto mt-6">
                                      <button
                                        id="backHomeBtn"
                                        className="backHomeBtn mx-auto"
                                        onClick={backHomeBtn}
                                      >
                                        {elements.home_button
                                          ? elements.home_button.value
                                          : ""}
                                        <i className="fal fa-chevron-circle-right pl-1 pl-sm-2"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CaseStatusBanner elements={elements} modular={modular} />
                </div>
              </div>
            </div>
            <div id="dev_modal" className="modal modal-select">
              <div className="modal-content">
                <div className="modal-header">
                  <img
                    className="icon-modal"
                    src="https://preview-assets-us-01.kc-usercontent.com:443/b2956330-c34f-0064-2c6f-27bd5c0147fc/bc429bc6-4968-4ed0-acf6-4f67889b9692/exclamation.gif"
                    alt=""
                  />
                  <h3 className="mt-1 d-inline text-white">Atención</h3>
                </div>
                <div className="modal-text">
                  <p id="dev_text">{messageModal}</p>
                  <button
                    id="modal_btn"
                    className="modalBtn"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(Form);
