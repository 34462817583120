import React from "react";

const Banner = (props) => {
  return (
    <div className="px-0">
      <img
        className="img-banner-landing img-fluid d-md-block"
        alt="Banner"
        src={props.url}
        id="main-banner"
      />
    </div>
  );
};

export default Banner;
