import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Form from "./Form";

import kentico from "../services/KenticoService.js";
import { getCulture } from "../store/culture/reducer";
import { connect } from "react-redux";

const Main = (props) => {
  const [elements, setElements] = useState();
  const [modular, setModular] = useState();
  const [isCultureVisible, setIsCultureVisible] = useState("no");

  const lang = `${props.culture.language}_${props.culture.country}`;

  useEffect(() => {
    const getData = async () => {
      return kentico
        .getItemData("reclamos", lang)
        .then((response) => {
          setElements(response.data.item.elements);
          setModular(response.data.modular_content);
          const publishOptions =
            response.data.item.elements.publish_options.value[0];

          setIsCultureVisible(
            response.data.modular_content[publishOptions].elements.visible
              .value[0].codename
          );
        })
        .catch((err) => {
          console.error("Error en kentico.getItemData: ", err);
        });
    };
    getData();
  }, [lang]);

  return "yes" !== isCultureVisible ? null : (
    <>
      <Banner url={elements.banner.value[0].url} />
      <Form elements={elements} modular={modular} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, {})(Main);
