import axios from 'axios';

const baseUrl = process.env.REACT_APP_DELIVERY_URL;
const projectId = process.env.REACT_APP_DELIVERY_ID;
const previewToken = process.env.REACT_APP_DELIVERY_PREVIEW_TOKEN;
const env = process.env.REACT_APP_ENV;

let header = {
    'Content-Type': 'application/json',
    'Accept': '*/*'
};

const getItemData = (obj, culture = process.env.REACT_APP_DEFAULT_CULTURE) => {
    
  
    

  let url = `${baseUrl}/${projectId}/items/${obj}?language=${culture}`;


 

  header = {
    ...header,
    Authorization: `Bearer ${previewToken}`
  };

  if (env !== 'production') {
     return axios.get(url, {
               headers: header
            });
  }else{
    return axios.get(url);
  }


};

const exportedObject = {
     getItemData
};

export default exportedObject;
