import { Fragment, useEffect, useState } from "react";

import Footer from "./components/Footer.js";
import Header from "./components/Header.js";

//Redux
import TagManager from "react-gtm-module";
import { connect } from "react-redux";

import Main from "./components/Main.jsx";
import updateCulture from "./store/culture/action";
import { getCulture } from "./store/culture/reducer.js";

const App = (props) => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
  };

  const [loader, setLoader] = useState(false);

  const validateAndSet = (params) => {
    props.updateCulture({
      country: params.country,
      language: params.lang,
      url: `/${params.country}/${params.lang}`,
      culture: `${params.lang}_${params.country}`,
    });

    setLoader(true);
  };

  TagManager.initialize(tagManagerArgs);

  useEffect(() => {
    validateAndSet(props.match.params);
  }, [props.match.params]);

  return (
    <div className="App">
      {loader ? (
        <Fragment>
          <Header />
          <Main />
          <Footer />
        </Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    culture: getCulture(state),
  };
};

export default connect(mapStateToProps, { updateCulture })(App);
