let initialState = {
  culture: {
    country: process.env.REACT_APP_DEFAULT_COUNTRY,
    language: process.env.REACT_APP_DEFAULT_LANGUAGE,
    url: process.env.REACT_APP_DEFAULT_URI,
    culture: process.env.REACT_APP_DEFAULT_CULTURE,
    elements: {},
  }
};

export const getCulture = (state) => {
    return state.cultureReducer.culture;
};

const updateCulture = (state = initialState, action) => {
  if(action.type === 'UPDATE_CULTURE') {
    return {
      ...state,
      culture: action.payload
    }
  }

  return state;
}

export default updateCulture;
