const updateCulture = culture => {
  return {
    type: 'UPDATE_CULTURE',
    payload: culture
  };
};

export default updateCulture;

export const resetCulture = () => {
  return {
    type: 'UPDATE_CULTURE',
    payload: {
      country: process.env.REACT_APP_DEFAULT_COUNTRY,
      language: process.env.REACT_APP_DEFAULT_LANGUAGE,
      url: process.env.REACT_APP_DEFAULT_URI,
      culture: process.env.REACT_APP_DEFAULT_CULTURE
    }
  };
};
