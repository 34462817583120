import {createStore, combineReducers} from 'redux';
import cultureReducer from './culture/reducer';

const reducers = combineReducers({
  cultureReducer
});

const store = createStore(
  reducers
);

export default store
