import React from "react";
import ReactDOM from "react-dom";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import App from "./App";

import { Provider } from "react-redux";
import store from "./store";

import history from "./history";
import registerServiceWorker from "./serviceWorker";

const loadTealium = () => {
  const script = document.createElement("script");
  script.src = process.env.REACT_APP_TEALIUM;
  script.async = true;
  document.head.appendChild(script);
};
const ambiente = process.env.REACT_APP_ENV;
console.log("// Version 21.0.07 //");
console.log("enviroment :", ambiente);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <div>
          <Switch>
            <Route
              exact
              path="/:country/:lang/formulario-contacto"
              component={App}
            />
            <Redirect
              from="/"
              to={`${process.env.REACT_APP_DEFAULT_URI}/${process.env.REACT_APP_ROUTE_NAME}`}
            />
          </Switch>
        </div>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

loadTealium();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
registerServiceWorker();
