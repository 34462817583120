import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import claim from "../services/ClaimService";
const CaseStatusBanner = ({ elements, modular }) => {
  const [statusCaseStep, setStatusCaseStep] = useState(1);
  const [emailInput, setEmailInput] = useState("");
  const [caseIdInput, setCaseIdInput] = useState("");
  const [caseResults, setCaseResults] = useState({
    data: {},
    msg: "",
    status: "idle",
  });
  const [emailInputError, setEmailInputError] = useState("");
  const [caseIdInputError, setCaseIdInputError] = useState("");
  const [loading, setLoading] = useState(false);
  const submitButtonRef = useRef();
  const [caseToShow, setCaseToShow] = useState({
    idCaso: "",
    date: "",
    estado: "",
    message: "",
    info: "",
  });

  const handleBannerClick = () => {
    setCaseResults({
      data: {},
      msg: "",
      status: "idle",
    });
    setEmailInputError("");
    setCaseIdInputError("");
    setEmailInput("");
    setCaseIdInput("");
    setStatusCaseStep(1);
    window.$("#status_case_modal").modal("show");
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailInput = (email) => {
    setEmailInputError("");
    setEmailInput(email);
  };

  const handleCaseIdInput = (caseId) => {
    // Regex for non numeric values
    const nonNumRegex = /\D/g;

    // Set id caso replacing non numeric values
    setCaseIdInput(caseId.replace(nonNumRegex, ""));
    setCaseIdInputError("");
  };

  const handleFormSubmit = async () => {
    if (!emailInput || !caseIdInput) {
      !emailInput &&
        setEmailInputError(modular.case_status_email.elements.error.value);
      !caseIdInput &&
        setCaseIdInputError(modular.case_status_id.elements.error.value);
      return;
    }
    if (!validateEmail(emailInput)) {
      setEmailInputError(modular.case_status_email.elements.error.value);
      return;
    }
    // loadingRef?.current.startLoader();
    setLoading(true);
    let result = {
      data: {},
      msg: "",
      status: "success",
    };
    try {
      const axiosResponse = await claim.getCaseStatus({
        idCaso: caseIdInput,
        descripcionMail: emailInput,
      });
      // loadingRef?.current.stopLoader();
      const { data } = axiosResponse;

      process.env.REACT_APP_ENV !== "prod" &&
        console.log("Respuesta axios: ", axiosResponse);

      if (data.code === "00") {
        console.log("Response 00: ");
        if (
          data?.data?.resultado === 2 ||
          data?.data?.resultado === 0 ||
          data?.data?.contenido?.length === 0
        ) {
          result.msg = elements.case_status_no_exist_txt.value;
        }
        if (data?.data?.contenido?.length > 0) {
          result.data = data?.data?.contenido[0];
        }
      } else {
        console.error("Response distinto 00: ");
        result.msg = elements.case_status_error_txt.value;
        result.status = "failed";
      }
    } catch (error) {
      console.error("dInside Claim Service error: ", error);
      result.status = "failed";
      result.msg = elements.case_status_error_txt.value;
    }
    setCaseResults(result);
    setStatusCaseStep(2);
    setLoading(false);
  };
  const handleResetCase = () => {
    setStatusCaseStep(1);
    setEmailInput("");
    setCaseIdInput("");
    setCaseResults({ data: {}, msg: "", status: "idle" });
  };

  useEffect(() => {
    if (Object.keys(caseResults.data).length === 0) return;
    const {
      data: { idCaso, estado, fechaCierre, fechaActualizacion, fechaCreacion },
    } = caseResults;

    const {
      case_status_c_sin_asignar,
      case_status_c_cerrado,
      case_status_c_asignado,
      case_status_date_close_txt,
      case_status_date_update_txt,
    } = elements;

    const date = fechaCierre
      ? fechaCierre
      : fechaActualizacion
      ? fechaActualizacion
      : fechaCreacion;

    const message =
      estado === "Sin Asignar"
        ? case_status_c_sin_asignar.value
        : estado === "Cerrado"
        ? case_status_c_cerrado.value
        : case_status_c_asignado.value;

    const info =
      estado === "Cerrado"
        ? case_status_date_close_txt.value
        : case_status_date_update_txt.value;

    setCaseToShow({
      idCaso: idCaso,
      date,
      estado: estado,
      message,
      info,
    });
  }, [caseResults, elements]);

  return (
    <>
      <div className="row">
        <div
          className="col"
          onClick={handleBannerClick}
          style={{ cursor: "pointer" }}
        >
          <img
            className="img-fluid"
            src={elements.case_status_banner.value[0].url}
            alt="check Status"
          />
        </div>
      </div>

      <div id="status_case_modal" className="modal modal-select">
        <div className="modal-content">
          <div className="modal-header">
            <div className="h3 mt-1 d-inline text-white">
              {((caseResults.status === "success" &&
                Object.keys(caseResults.data).length === 0) ||
                caseResults.status === "failed") && (
                <div className="col">
                  <div className="row">
                    <img
                      className="icon-modal img-fluid"
                      src="https://preview-assets-us-01.kc-usercontent.com:443/b2956330-c34f-0064-2c6f-27bd5c0147fc/bc429bc6-4968-4ed0-acf6-4f67889b9692/exclamation.gif"
                      alt=""
                    />
                    <div className="h3 d-inline text-white">
                      {parse(elements.title.value, { trim: true })}
                    </div>
                  </div>
                </div>
              )}

              {((caseResults.status === "success" &&
                Object.keys(caseResults.data).length > 0) ||
                caseResults.status === "idle") &&
                elements.case_status_title.value}
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              disabled={loading}
            >
              <div className="" aria-hidden="true">
                ×
              </div>
            </button>
          </div>
          <div className="modal-text">
            {statusCaseStep === 1 && (
              <form>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="h5 mb-3">
                        {elements.case_status_form_txt.value}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="case_status_email" className="mb-0">
                          {modular.case_status_email.elements.label.value}
                        </label>
                        <input
                          value={emailInput}
                          onKeyPress={(e) => {
                            if (e.key === "Enter")
                              submitButtonRef.current.click();
                          }}
                          onChange={(e) =>
                            handleEmailInput(e.currentTarget.value)
                          }
                          id="case_status_email"
                          name="case_status_email"
                          aria-label={
                            modular.case_status_email.elements.ph.value
                          }
                          placeholder={
                            modular.case_status_email.elements.ph.value
                          }
                          autoComplete="off"
                          className="form-control shadow-none"
                        />
                      </div>
                      {emailInputError && (
                        <div className=" error-message text-left mb-3">
                          {emailInputError}
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="case_status_id" className="mb-0">
                          {modular.case_status_id.elements.label.value}
                        </label>
                        <input
                          value={caseIdInput}
                          onKeyPress={(e) => {
                            if (e.key === "Enter")
                              submitButtonRef.current.click();
                            if (!/[0-9]/.test(e.key)) return e.preventDefault();
                          }}
                          onChange={(e) =>
                            handleCaseIdInput(e.currentTarget.value)
                          }
                          id="case_status_id"
                          className="form-control shadow-none"
                          name="case_status_id"
                          aria-label={modular.case_status_id.elements.ph.value}
                          placeholder={modular.case_status_id.elements.ph.value}
                          minLength={1}
                          maxLength={10}
                          autoComplete="off"
                        />
                      </div>
                      {caseIdInputError && (
                        <div className="error-message text-left">
                          {caseIdInputError}
                        </div>
                      )}
                    </div>
                    <div className="col-12 ml-auto mt-3 pl-0">
                      <button
                        disabled={loading}
                        type="button"
                        id="continueCaseStatus"
                        className="continueBtn disabled ml-auto"
                        onClick={handleFormSubmit}
                        ref={submitButtonRef}
                      >
                        {elements.case_status_btn_txt.value}
                        <i
                          className="fal fa-chevron-circle-right pl-1 pl-sm-2"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {statusCaseStep === 2 && (
              <>
                {caseResults.status === "success" &&
                Object.keys(caseResults.data).length > 0 ? (
                  <div className="row">
                    <div className="col-12 my-2">
                      {elements.case_status_id_text.value}
                      <div className="h3 lightblue-text">
                        {caseToShow.idCaso}
                      </div>
                    </div>

                    <div className="col-12 my-2">{caseToShow.message}</div>
                    <small className="col-12">{caseToShow.info}</small>
                    <small className="col-12 lightblue-text">
                      {caseToShow.date.substring(0, caseToShow.date.length - 4)}
                    </small>

                    <div className="col-12 ml-auto mt-3">
                      <button
                        type="button"
                        id="anotherCaseStatus"
                        className="backBtn mx-auto btn"
                        onClick={handleResetCase}
                      >
                        <i
                          className="fal fa-chevron-circle-left pr-1 pr-sm-2"
                          aria-hidden="true"
                        ></i>
                        <div className="">
                          {elements.case_status_another_btn_txt.value}
                        </div>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="row">
                      <div className="col">{caseResults.msg}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <button
                          id="case_status_not_found_btn"
                          className="modalBtn"
                          onClick={handleResetCase}
                        >
                          {elements.modal_btn_text.value}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStatusBanner;
