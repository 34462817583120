import axios from "axios";

let baseUrl = process.env.REACT_APP_ENDPOINT_CLAIM;
const token = process.env.REACT_APP_KEY_CLAIM;

const headers = {
  "Content-Type": "application/json",
  Accept: "*/*",
  "X-Api-Key": `${token}`,
};

const createClaim = (data) => {
  let url = `${baseUrl}/cases`;
  const timestamp = Math.floor(Date.now() / 1000); // Converts milliseconds to seconds
  headers["X-Timestamp"] = timestamp;

  return axios.post(url, data, {
    headers,
  });
};

const getPNR = (pnr, email, lastname) => {
  let url = `${baseUrl}/navitaire`;

  return axios.get(url, {
    headers,
    params: {
      pnr,
      email,
      lastname,
    },
  });
};

const createPdfFile = (data) => {
  let url = `${baseUrl}/pdf`;

  return axios.post(url, data, {
    headers,
  });
};

const sendMail = (data) => {
  let url = `${baseUrl}/mail`;

  return axios.post(url, data, {
    headers,
  });
};

const getCaseStatus = (data) => {
  let url = `${baseUrl}/status`;
  const timestamp = Math.floor(Date.now() / 1000); // Converts milliseconds to seconds
  headers["X-Timestamp"] = timestamp;
  return axios.post(url, data, {
    headers,
  });
};

const exportedObjectClaim = {
  createClaim,
  getPNR,
  createPdfFile,
  sendMail,
  getCaseStatus,
};

export default exportedObjectClaim;
